<template>
    <div>
      <UnsubmittedLiquidations/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import UnsubmittedLiquidations from '@/components/UnsubmittedLiquidations.vue'
  
  export default {
    name: 'activity-closure',
    components: {
      UnsubmittedLiquidations
    }
  }
  </script>
  