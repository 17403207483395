<template>
    <div>
      <TransitionRoot appear :show="isMessageOpen" @close="closeMessageModal" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    :class=" isOk ? 'text-size font-medium leading-6 text-emerald-500 flex space-x-0.5 items-center' : 'text-size items-center space-x-0.5 flex font-medium leading-6 text-red-500'"
                  >
                    <svg class="h-5 w-5 fill-current animate-pulse" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23.91,17.05l-1.65-7.82c-1.03-3.67-4.42-6.24-8.26-6.24-4.05,0-7.49,2.74-8.37,6.69l-1.55,7.43c-.21,.95,.02,1.93,.63,2.69,.61,.76,1.52,1.19,2.49,1.19h3.35c.24,1.69,1.69,3,3.45,3s3.21-1.31,3.45-3h3.35c.93,0,1.79-.39,2.39-1.07l.16-.19c.61-.8,.81-1.82,.56-2.69Zm-2.93,.86h0c-.04,.06-.09,.09-.16,.09H7.2c-.07,0-.12-.02-.16-.07-.04-.05-.05-.1-.03-.17l1.55-7.43c.57-2.55,2.81-4.33,5.44-4.33,2.5,0,4.7,1.66,5.35,3.95l1.65,7.82c.01,.05,0,.1-.02,.15ZM5.26,5.61c-.73,1.08-1.29,2.32-1.59,3.67l-1.54,7.41c-.08,.35-.12,.71-.12,1.06-.5-.2-.95-.51-1.3-.94-.61-.76-.84-1.74-.63-2.69L1.62,6.69C2.51,2.74,5.95,0,10,0c1.43,0,2.81,.36,4.02,1,0,0-.01,0-.02,0-3.26,0-6.2,1.46-8.15,3.83-.02,.02-.03,.04-.05,.06-.16,.19-.31,.39-.45,.6-.03,.05-.06,.09-.09,.13Z"/></svg>
                    <span>{{ title }}</span>
                  </DialogTitle>
                  <div class="my-1">
                    <p class="text-size text-gray-500 whitespace-pre px-4">
                    {{message}}
                    </p>
                  </div>
  
                  <div class="mt-2 flex justify-center">
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="closeMessageModal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  
  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
      // DialogTitle
    },
    setup () {
      const isMessageOpen = ref(false)
      return {
        isMessageOpen,
        closeMessageModal () {
          isMessageOpen.value = false
        },
        openMessageModal () {
          isMessageOpen.value = true
        }
      }
    },
    name: 'message-modal',
    props: {
      isOk: Boolean,
      title: String,
      message: String
    },
    data: () => {
      return {
      }
    },
    methods: {
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  </style>
  