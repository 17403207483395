<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> >My Signature</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Signature pad -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The signature pad -->
              <div class="ml-8 flex mt-4 mb-1 space-x-1 items-center">
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg>
                <p class="text-left text-size-md font-medium text-gray-600">My Signature </p>
              </div>
              <div class="flex-1">
                <button
                  @click="switchView(0)"
                  :class=" currentView == 0 ? 'shadow inline-block box-shadow text-size-sm px-36 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-500 transition duration-150 ease-in-out'">
                    My Signature
                </button>
                <button
                  @click="switchView(1)"
                  :class=" currentView == 1 ? 'shadow inline-block box-shadow text-size-sm px-36 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-500 transition duration-150 ease-in-out'">
                    Sign My Signature
                </button>
              </div>
              <div class="mx-8 mb-5 mt-4 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 ">
                <!-- the signature -->
                <div class=" border-gray-600 overflow-hidden bg-red">
                  <!-- show the signature -->
                  <div class="flex items-center" v-if=" currentView== 0">
                    <img class="h-64 w-full" :src="signatureLink"/>
                  </div>
                  <!-- show the signature pad -->
                  <div class="flex items-center" v-else>
                    <vue3Signature
                      ref="signature"
                      :sigOption="option"
                      :w="'100%'"
                      :h="'256px'"
                      :disabled="disabled"
                      class="touch-none mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1">
                <div class="flex-1 mb-5">
                  <button
                    v-if="currentView == 0"
                    @click="currentView = 1"
                    class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      New Signature
                  </button>
                  <button
                    v-if="currentView == 1"
                    @click="clearSignature()"
                    class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      Clear Signature
                  </button>
                  <button
                    v-if="currentView == 1"
                    @click="submit()"
                    class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      Submit Signature
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <!-- Loader modal -->
       <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import vue3Signature from "vue3-signature";
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  // import { retry, zip } from 'rxjs'
  import { ref } from 'vue'
  import LoadingModal from './LoadingModal.vue'
  import MessageModal from './MessageModal.vue'
  // import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

  
  export default {
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token',
      ])
    },
    components: {
      Navbar,
      Sidebar,
      // TransitionRoot,
      // TransitionChild,
      // Dialog,
      // DialogOverlay,
      LoadingModal,
      MessageModal,
      vue3Signature
    },
    setup () {
      const isOpen = ref(false)
      return {
        isOpen,
        closeModal () {
          isOpen.value = false
        },
        openModal () {
          isOpen.value = true
        }
      }
    },
    name: 'signature-pad',
    props: {
    },
    data: () => {
      return {
        firstname: '',
        surname: '',
        email: '',
        company: {},
        location: {},
        showProfile: false,
        employeeProxyData: null,
        expandBody: false,
        selectedEmployees: {},
        selectedReason: {},
        dataLoaded: false,
        currentView: 1,
        BASE_URL: process.env.VUE_APP_BASE_URL ,
        startDate: '',
        endDate: '',
        isOk: false,
        messageTitle: '',
        message: '',
        disabled: false,
        signatureLink: null,
        option: {
          penColor: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255,255,255)",
        },
        signature: null
      }
    },
    mounted () {
      // removing the submenu items selection
      this.$store.commit('mutateActiveListItemIndex', 5)
      this.$store.commit('mutateSelectedSubMenuItem', 7)
      // get the employee signature
      this.getEmployeeSignature()
    },
    methods: {
      // get the employee signature
      getEmployeeSignature () {
        const observable = ajax({
          url: this.BASE_URL + '/signature/findByEmployeeId?id=' + this.id,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        const observer = {
          next: (response) => {
            const data = response.response
            this.signature = data
            
            // check the data size if its there show the signature
            if (this.signature.length > 0) {
              // get the image blob and display it
              this.getSignature(this.signature[0].filename)
            }
          },
          error: () => {},
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      getSignature (filename) {
        // download the signature
        this.$refs.loadingModal.openLoaderModal()
          const observable = ajax({
            method: 'GET',
            url: this.BASE_URL + '/signature/download?filename=' + filename,
            headers: {
              Authorization: `Bearer ${this.token}`
            },
            responseType: 'blob'
          })

          const observer = {
            next: (response) => {
              const blob = response.response
              this.signatureLink = window.URL.createObjectURL(blob);
              // const link = document.createElement('a');
              // link.href = url;
              // link.setAttribute('download', filename || url.split('/').pop());
              // document.body.appendChild(link);
              // link.click();
              // link.remove();
            },
            error: () => {
              // close the loader modal
              this.$refs.loadingModal.closeLoaderModal()
              // showing the dialog for the error
              this.isOk = false
              this.messageTitle = 'Error Message'
              this.message = 'Failed to download the travel request.'
              this.$refs.messageModal.openMessageModal()
            },
            complete: () => {
              this.$refs.loadingModal.closeLoaderModal()
            }
          }

          observable.subscribe(observer)
        
      },
      // submits the signature
      submit () {
        const isEmpty = this.$refs.signature.isEmpty()
        if (!isEmpty) {
          // open the loading modal
          this.$refs.loadingModal.openLoaderModal()
          
          var signature = this.$refs.signature.save()

          const observable = ajax({
            url: this.BASE_URL + '/signature/insert',
            method: 'POST',
            body: {
              employee_id: this.id,
              filename: signature
            },
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          })

          const observer = {
            next: () => {},
            error: () => {
               // close the loader modal
              this.$refs.loadingModal.closeLoaderModal()
              // showing the dialog for the error
              this.isOk = false
              this.messageTitle = 'Error Message'
              this.message = 'Failed to submit your signature, please try again later...'
              this.$refs.messageModal.openMessageModal()
            },
            complete: () => {
              this.$refs.loadingModal.closeLoaderModal()
              this.getEmployeeSignature()
            }
          }

          observable.subscribe(observer)
        }
      },
      // removes the signature
      clearSignature () {
        this.$refs.signature.clear()
      },
      switchView (item) {
        
        switch (item) {
          case 0:
            this.currentView = item
            break
        
          default:
            this.currentView = item
            break

        }

      },
      deleteSignature (id) {
        // close the delegates modal
        this.closeModal()

        // open the loading modal
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
          url: this.BASE_URL + '/signature/delete?id=' + id,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        const observer = {
          next: () => {
            // this.isOk = true
            // this.messageTitle = 'Message'
            // this.message = 'Successfully deleted the delegation details..'
            // this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to delete the delegation details please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
            this.getEmployeeSignature()
          }
        }
        
        observable.subscribe(observer)
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
    #signature-pad-form {
      max-width: 300px;
      margin: 0 auto ;
    }

    /* #signature-pad {
      cursor: pointer;
      border: 2px solid var(__);
    } */
  
  </style>
  