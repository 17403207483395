<template>
    <div>
      <ExpenseReport/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ExpenseReport from '@/components/EditExpenseReport.vue'
  
  export default {
    name: 'expense-report',
    components: {
      ExpenseReport
    }
  }
  </script>
  