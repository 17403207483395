<template>
  <div>
    <ViewLiquidation/>
  </div>
</template>

<script>
// @ is an alias to /src
import ViewLiquidation from '@/components/AdminViewLiquidation.vue'

export default {
  name: 'admin-view-liquidation',
  components: {
    ViewLiquidation
  }
}
</script>
