<template>
    <div>
      <PendingApproval/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import PendingApproval from '@/components/PendingApproval.vue'
  
  export default {
    name: 'penidng-approval-view',
    components: {
      PendingApproval
    }
  }
  </script>
  