<template>
    <div class="ActivityTypeManagement">
      <ActivityTypeManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ActivityTypeManagement from '@/components/ActivityTypeManagement.vue'
  
  export default {
    name: 'activity-type-management-view',
    components: {
      ActivityTypeManagement
    }
  }
  </script>
  