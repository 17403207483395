<template>
  <div>
    <UnsubmittedRequests/>
  </div>
</template>

<script>
// @ is an alias to /src
import UnsubmittedRequests from '@/components/UnsubmittedRequests.vue'

export default {
  name: 'unsubmitted-request-view',
  components: {
    UnsubmittedRequests
  }
}
</script>
