<template>
    <div>
      <EditMemo/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EditMemo from '@/components/EditMemo.vue'
  
  export default {
    name: 'edit-memo-view',
    components: {
      EditMemo
    }
  }
  </script>
  