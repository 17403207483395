<template>
    <div class="home">
      <Home/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Home from '@/components/EmployeeHome.vue'
  
  export default {
    name: 'employee-home-view',
    components: {
      Home
    }
  }
  </script>
  