<template>
    <div>
      <PendingLiquidation/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import PendingLiquidation from '@/components/PendingLiquidation.vue'
  
  export default {
    name: 'penidng-liquidation-view',
    components: {
      PendingLiquidation
    }
  }
  </script>
  