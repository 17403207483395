<template>
    <div class="timeline">
      <Profile/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Profile from '@/components/Profile.vue'
  
  export default {
    name: 'profile-view',
    components: {
      Profile
    }
  }
  </script>
  