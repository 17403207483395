<template>
    <div class="timeline">
      <SupervisorManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import SupervisorManagement from '@/components/SupervisorManagement.vue'
  
  export default {
    name: 'supervisor-management',
    components: {
      SupervisorManagement
    }
  }
  </script>
  