<template>
    <div>
      <TransitionRoot appear :show="isPROpen" @close="closePRModal" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-50 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-6xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    :class=" isOk ? 'text-size font-medium leading-6 text-emerald-500' : 'text-size font-medium leading-6 text-gray-500'"
                  >
                    <div class="flex items-center space-x-0.5">
                      <svg class=" fill-emerald-600 w-5 h-5" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m10 10a3 3 0 0 0 0 6h4a3 3 0 0 0 0-6zm5 3a1 1 0 0 1 -1 1h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 1 1zm2 6a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm2.536-15.879-1.658-1.656a4.968 4.968 0 0 0 -3.535-1.465h-6.343a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5v-12.343a4.968 4.968 0 0 0 -1.464-3.536zm-1.414 1.414a3.1 3.1 0 0 1 .378.465h-2.5v-2.5a2.932 2.932 0 0 1 .464.377zm.878 14.465a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3-3v-14a3 3 0 0 1 3-3h6v3a2 2 0 0 0 2 2h3z"/></svg>
                      <span>Purchase Requisition</span>
                    </div>
                  </DialogTitle>
                  <div class="my-4 grid grid-cols-2 gap-4">
                    <div class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Budget Line <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="budgetLine"
                        placeholder="Budget Line"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Budget Amount <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="budgetAmount"
                        placeholder="Budget Amount"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div class="">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">District <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'districtId'"
                            :url="'/district/search'"
                            :persistedSelection="districtName"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              districtName = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                      </div>
                      </div>
                      </div>
                    <div class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Specific Area/Region <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="area"
                        placeholder="Specific Region/Area"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Specification of Goods/Services <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="specification"
                        placeholder="Specification of Goods & Services"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.accommodation.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Check In Date <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="checkInDate"
                        placeholder="Check In Date"
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.accommodation.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Check Out Date <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="checkOutDate"
                        placeholder="Check In Date"
                        type="date"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.accommodation.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">No of Single Rooms <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="singleRooms"
                        placeholder="No of Single Rooms Needed"
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.accommodation.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">No of Double Rooms <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="doubleRooms"
                        type="number"
                        placeholder="No of Double Rooms Needed"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.conferencing.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Conference Package <span class="font-bold text-red-500">*</span></label>
                      <div class="grid grid-cols-6">
                        <div class="items-center">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">CP1</label>
                          <input
                            v-model="confPackage"
                            type="radio"
                            value="CP1"
                            name="package"
                            class="bg-gray-50 text-gray-600 border px-2 py-3 ml-1 border-gray-300 focus:outline-none"/>
                        </div>
                        <div class="items-center">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">CP2</label>
                          <input
                            v-model="confPackage"
                            value="CP2"
                            placeholder="Confrerence Package"
                            type="radio"
                            name="package"
                            class="text-size-md text-gray-600 border px-2 py-3 ml-1 border-gray-300 focus:outline-none"/>
                        </div>
                        <div class="items-center">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">CP3</label>
                          <input
                            v-model="confPackage"
                            value="CP3"
                            placeholder="Confrerence Package"
                            type="radio"
                            name="package"
                            class="text-size-md text-gray-600 border px-2 py-3 ml-1 border-gray-300 focus:outline-none"/>
                        </div>
                        <div class="flex-1 items-center">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">CP4</label>
                          <input
                            v-model="confPackage"
                            value="CP4"
                            placeholder="Confrerence Package"
                            type="radio"
                            name="package"
                            class="text-size-md text-gray-600 border px-2 py-3 ml-1 border-gray-300 focus:outline-none"/>
                        </div>
                        <div class="items-center">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">CP5</label>
                          <input
                            v-model="confPackage"
                            value="CP5"
                            placeholder="Confrerence Package"
                            type="radio"
                            name="package"
                            class="text-size-md text-gray-600 border px-2 py-3 ml-1 border-gray-300 focus:outline-none"/>
                        </div>
                        <div class="items-center flex-1">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Hall Hire</label>
                          <input
                            v-model="confPackage"
                            value="Hall Hire"
                            placeholder="Confrerence Package"
                            type="radio"
                            name="package"
                            class="text-size-md text-gray-600 ml-4 border px-2 py-3 border-gray-300 focus:outline-none"/>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.conferencing.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Quantity Per Participant <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="Qparticipants"
                        type="number"
                        placeholder="Quantity Per Participant"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.conferencing.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">No of Participants <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="noParticipants"
                        type="number"
                        placeholder="No of Participants"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.conferencing.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">No of Days Needed <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="days"
                        type="number"
                        placeholder="No of Days Needed"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div v-if="this.conferencing.name === 'Yes'" class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Delivery Time <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="deliveryTime"
                        type="time"
                        placeholder="Reason for denying the request"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                    <div class="space-y-0.5">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Delivery Date <span class="font-bold text-red-500">*</span></label>
                      <input
                        v-model="deliveryDate"
                        type="date"
                        placeholder="Reason for denying the request"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
  
                  <div class="mt-2 flex justify-center space-x-1">
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-red-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="closePRModal()"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-red-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="submit()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import CustomDropDown from './CustomDropDown.vue'
  import { mapState } from 'vuex'
  
  export default {
    computed: {
      ...mapState([
        'editPr'
      ])
    },
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      CustomDropDown
      // DialogTitle
    },
    setup () {
      const isPROpen = ref(false)
      return {
        isPROpen,
        closePRModal () {
          isPROpen.value = false
        },
        openPRModal () {
          isPROpen.value = true
        }
      }
    },
    name: 'message-modal',
    props: {
      conferencing: Object,
      accommodation: Object
    },
    mounted () {
      this.loadVuexData()
    },
    data: () => {
      return {
        specification: '',
        budgetLine: '',
        budgetAmount: 0,
        districtName: {},
        area: '',
        checkInDate: '',
        checkOutDate: '',
        singleRooms: 0,
        doubleRooms: 0,
        confPackage: '',
        Qparticipants: '',
        noParticipants: 0,
        days: 0,
        deliveryTime: '',
        deliveryDate: '',
        hasConferencing: false,
        hasAccommodation: false
      }
    },
    methods: {
      // loads the stored vuex data
      loadVuexData () {
        if (this.editPr !== null) {
          if (this.editPr.budgetLine !== undefined && this.editPr.budgetLine !== null) {
            this.budgetLine = this.editPr.budgetLine
          }
          if (this.editPr.budgetAmount !== undefined && this.editPr.budgetAmount !== null) {
            this.budgetAmount = this.editPr.budgetAmount
          }
          if (this.editPr.districtName.id !== undefined && this.editPr.districtName.id !== null) {
            this.districtName = { id: this.editPr.districtName.id, name: this.editPr.districtName.name }
          }
          if (this.editPr.area !== undefined && this.editPr.area !== null) {
            this.area = this.editPr.area
          }
          if (this.editPr.specification !== undefined && this.editPr.specification !== null) {
            this.specification = this.editPr.specification
          }
          if (this.editPr.checkInDate !== undefined && this.editPr.checkInDate !== null) {
            this.checkInDate = this.editPr.checkInDate
          }
          if (this.editPr.checkOutDate !== undefined && this.editPr.checkOutDate !== null) {
            this.checkOutDate = this.editPr.checkOutDate
          }
          if (this.editPr.singleRooms !== undefined && this.editPr.singleRooms !== null) {
            this.singleRooms = this.editPr.singleRooms
          }
          if (this.editPr.doubleRooms !== undefined && this.editPr.doubleRooms !== null) {
            this.doubleRooms = this.editPr.doubleRooms
          }
          if (this.editPr.deliveryDate !== undefined && this.editPr.deliveryDate !== null) {
            this.deliveryDate = this.editPr.deliveryDate
          }
          if (this.editPr.confPackage !== undefined && this.editPr.confPackage !== null) {
            this.confPackage = this.editPr.confPackage
          }
          if (this.editPr.Qparticipants !== undefined && this.editPr.Qparticipants !== null) {
            this.Qparticipants = this.editPr.Qparticipants
          }
          if (this.editPr.noParticipants !== undefined && this.editPr.noParticipants !== null) {
            this.noParticipants = this.editPr.noParticipants
          }
          if (this.editPr.days !== undefined && this.editPr.days !== null) {
            this.days = this.editPr.days
          }
          if (this.editPr.deliveryTime !== undefined && this.editPr.deliveryTime !== null) {
            this.deliveryTime = this.editPr.deliveryTime
          }
        }
      },
      submit () {
        // save the pr & close the modal
        this.savePR()
        this.closePRModal()
      },
      savePR () {
        const prData = {
          specification: this.specification,
          budgetLine: this.budgetLine,
          budgetAmount: this.budgetAmount,
          districtName: this.districtName,
          area: this.area,
          checkInDate: this.checkInDate,
          checkOutDate: this.checkOutDate,
          singleRooms: this.singleRooms,
          doubleRooms: this.doubleRooms,
          confPackage: this.confPackage,
          Qparticipants: this.Qparticipants,
          noParticipants: this.noParticipants,
          days: this.days,
          deliveryTime: this.deliveryTime,
          deliveryDate: this.deliveryDate
        }
        this.$store.commit('mutateEditPr', prData)
        // this.$emit('pr', prData)
      },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  </style>
  