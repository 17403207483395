<template>
    <div>
      <AdminViewRequest/>
    </div>
  </template>
  
  <script>
    // @ is an alias to /src
    import AdminViewRequest from '@/components/AdminViewRequest.vue'
    
    export default {
      name: 'view-approved-request-view',
      components: {
        AdminViewRequest
      }
    }
  </script>
  