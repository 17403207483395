<template>
    <div class="EmployeeBanking">
      <EmployeeBanking/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EmployeeBanking from '@/components/EmployeeBanking.vue'
  
  export default {
    name: 'admin-EmployeeBanking-view',
    components: {
      EmployeeBanking
    }
  }
  </script>
  