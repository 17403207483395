<template>
    <div>
      <CreateSchedule/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import CreateSchedule from '@/components/CreateSchedule.vue'
  
  export default {
    name: 'create-schedule-view',
    components: {
      CreateSchedule
    }
  }
  </script>
  