<template>
    <div>
      <ActivityClosure/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ActivityClosure from '@/components/ActivityClosure.vue'
  
  export default {
    name: 'activity-closure',
    components: {
      ActivityClosure
    }
  }
  </script>
  