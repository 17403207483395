<template>
    <div>
      <TransitionRoot appear :show="isMessageOpen" @close="closeMessageModal" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-2xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    :class=" isOk ? 'text-size font-medium leading-6 text-emerald-500' : 'text-size font-medium leading-6 text-red-500'"
                  >
                    {{ title }}
                  </DialogTitle>
                  <div class="my-1">
                    <textarea
                      rows="6"
                      v-model="feedback"
                      placeholder="Reason for denying the request"
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-red-400 focus:ring-1 focus:ring-red-600"/>
                  </div>
  
                  <div class="mt-2 flex justify-center">
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-red-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="submit()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  
  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
      // DialogTitle
    },
    setup () {
      const isMessageOpen = ref(false)
      return {
        isMessageOpen,
        closeMessageModal () {
          isMessageOpen.value = false
        },
        openMessageModal () {
          isMessageOpen.value = true
        }
      }
    },
    name: 'message-modal',
    props: {
      isOk: Boolean,
      title: String,
      message: String
    },
    data: () => {
      return {
        feedback: ''
      }
    },
    methods: {
      submit () {
        // send the feedback & close the modal
        this.emitEvent()
        this.closeMessageModal()
      },
      emitEvent () {
        this.$emit('selectedFeedback', this.feedback)
      },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  </style>
  