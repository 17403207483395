<template>
    <div>
      <EditAttachment/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EditAttachment from '@/components/EditAttachment.vue'
  
  export default {
    name: 'create-attachment-view',
    components: {
      EditAttachment
    }
  }
  </script>
  