<template>
    <div class="EmployeeManagement">
      <EmployeeManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EmployeeManagement from '@/components/EmployeeManagement.vue'
  
  export default {
    name: 'admin-EmployeeManagement-view',
    components: {
      EmployeeManagement
    }
  }
  </script>
  