<template>
    <div class="home">
      <Login/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Login from '@/components/Login.vue'
  
  export default {
    name: 'login-view',
    components: {
      Login
    }
  }
  </script>
  