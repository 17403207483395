<template>
    <div class="OfficeManagement">
      <OfficeManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import OfficeManagement from '@/components/OfficeManagement.vue'
  
  export default {
    name: 'admin-OfficeManagement-view',
    components: {
      OfficeManagement
    }
  }
  </script>
  