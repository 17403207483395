<template>
    <div class="LiquidationTimeline">
      <LiquidationTimeline/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import LiquidationTimeline from '@/components/LiquidationTimeline.vue'
  
  export default {
    name: 'liquidation-timeline-view',
    components: {
      LiquidationTimeline
    }
  }
  </script>
  