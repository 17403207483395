<template>
  <div>
    <ApprovedLiquidation/>
  </div>
</template>
  
  <script>
  // @ is an alias to /src
  import ApprovedLiquidation from '@/components/ApprovedLiquidation.vue';
  
  export default {
    name: 'penidng-approval-view',
    components: {
      ApprovedLiquidation
    }
  }
  </script>
  