<template>
    <div class="ProgramManagement">
      <ProgramManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ProgramManagement from '@/components/ProgramManagement.vue'
  
  export default {
    name: 'goal-Management-view',
    components: {
      ProgramManagement
    }
  }
  </script>
  