<template>
    <div class="timeline">
      <DelegateApprovals/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import DelegateApprovals from '@/components/DelegateApprovals.vue'
  
  export default {
    name: 'proxy-management-view',
    components: {
      DelegateApprovals
    }
  }
  </script>
  