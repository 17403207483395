import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import EmployeeHome from '../views/EmployeeHome.vue'
import CreateMemo from '../views/CreateMemo.vue'
import CreateBudget from '../views/CreateBudget.vue'
import CreateSchedule from '../views/CreateSchedule.vue'
import CreateSummary from '../views/CreateSummary.vue'
import PendingApproval from '../views/PendingApproval.vue'
import Timeline from '../views/Timeline.vue'
import ActivityClosure from '../views/ActivityClosure.vue'
import ExpenseReport from '../views/ExpenseReport.vue'
import Profile from '../views/Profile.vue'
import ProxyManagement from '../views/ProxyManagement.vue'
import RequestSummary from '../views/RequestSummary.vue'
import CreateAttachment from '../views/CreateAttachment'
import LiquidationAttachments from '../views/LiquidationAttachments.vue'
import ClosureSummary from '../views/ClosureSummary.vue'
import ParticipantPayments from '../views/ParticipantPayments.vue'
import RequestHistory from '../views/RequestHistory.vue'
import ViewRequestHistory from '../views/ViewRequestHistory.vue'
import ApproveRequest from '../views/ApproveRequest.vue'
import ApprovalHistory from '../views/ApprovalHistory.vue'
import ViewApprovedRequest from '../views/ViewApprovedRequest.vue'
import PendingLiquidation from '../views/PendingLiquidation.vue'
import ApproveLiquidation from '../views/ApproveLiquidation.vue'
import LiquidationApprovalHistory from '../views/LiquidationApprovalHistory.vue'
import LiquidationHistory from '../views/LiquidationHistory.vue'
import ViewLiquidationHistory from '../views/ViewLiquidationHistory.vue'
import ViewLiquidationApproval from '../views/ViewLiquidationApproval.vue'
import LiquidationTimeline from '../views/LiquidationTimeline.vue'
import Signature from '../views/Signature.vue'
import EditMemo from '../views/EditMemo.vue'
import EditBudget from '../views/EditBudget.vue'
import EditParticipantPayments from '../views/EditParticipantPayments.vue'
import EditSchedule from '../views/EditSchedule.vue'
import EditAttachment from '../views/EditAttachment.vue'
import EditRequestSummary from '../views/EditRequestSummary.vue'
import EditActivityClosure from '../views/EditActivityClosure.vue'
import EditExpenseReport from '../views/EditExpenseReport.vue'
import EditLiquidationAttachment from '../views/EditLiquidationAttachments.vue'
import EditClosureSummary from '../views/EditClosureSummary.vue'
import AdminHome from '../views/AdminHome.vue'
import FundingManagement from '../views/FundingManagement.vue'
import StakeholderManagement from '../views/StakeholderManagement.vue'
import PerDiemManagement from '../views/PerDiemManagement.vue'
import EmployeeManagement from '../views/EmployeeManagement.vue'
import DeactivatedAccounts from '../views/DeactivatedAccounts.vue'
import DelegateApprovals from '../views/DelegateApprovals.vue'
import PositionManagement from '../views/PositionManagement.vue'
import EmployeeBanking from '../views/EmployeeBanking.vue'
import OtherParticipants from '../views/OtherParticipants.vue'
import OfficeManagement from '../views/OfficeManagement.vue'
import VenueManagement from '../views/VenueManagement.vue'
import EmployeeRequests from '../views/EmployeeRequests'
import AdminViewRequest from '../views/AdminViewRequest'
import DownloadRequests from '../views/DownloadRequests'
import UnsubmittedRequests from '../views/UnsubmittedRequests'
import GoalManagement from '../views/GoalManagement'
import ProgramManagement from '../views/ProgramManagement'
import MainObjectives from '../views/MainObjectives'
import SpecificObjectives from '../views/SpecificObjectives'
import PivotYear from '../views/PivotYearManagement'
import ActivityType from '../views/ActivityTypeManagement'
import RequestType from '../views/RequestTypeManagement'
import DelegationReasons from '../views/DelegationReasons'
import StrategyManagement from '../views/StrategyManagement'
import EmployeeLiquidation from '../views/EmployeeLiquidation'
import AdminViewLiquidation from '../views/AdminViewLiquidation'
import DownloadLiquidation from '../views/DownloadLiquidation'
import UnsubmittedLiquidation from '../views/UnsubmittedLiquidations'
import SupervisorManagement from '../views/SupervisorManagement'
import ApprovedRequest from '@/views/ApprovedRequest.vue'
import ApprovedLiquidation from '@/views/ApprovedLiquidation.vue'
import RequestTimeline from '@/views/RequestTimeline.vue'
import AdminLiquidationTimeline from '@/views/AdminLiquidationTimeline.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/employee-home',
    name: 'employee-home',
    component: EmployeeHome
  },
  {
    path: '/create-memo',
    name: 'create-memo',
    component: CreateMemo
  },
  {
    path: '/create-budget',
    name: 'create-budget',
    component: CreateBudget
  },
  {
    path: '/create-schedule',
    name: 'create-schedule',
    component: CreateSchedule
  },
  {
    path: '/create-summary',
    name: 'create-summary',
    component: CreateSummary
  },
  {
    path: '/pending-approval',
    name: 'pendingApproval',
    component: PendingApproval
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline
  },
  {
    path: '/activity-closure',
    name: 'activity-closure',
    component: ActivityClosure
  },
  {
    path: '/expense-report',
    name: 'expense-report',
    component: ExpenseReport
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/proxy-management',
    name: 'proxy-management',
    component: ProxyManagement
  },
  {
    path: '/request-summary',
    name: 'request-summary',
    component: RequestSummary
  },
  {
    path: '/create-attachment',
    name: 'create-attachment',
    component: CreateAttachment
  },
  {
    path: '/liquidation-attachment',
    name: 'liquidation-attachment',
    component: LiquidationAttachments
  },
  {
    path: '/closure-summary',
    name: 'closure-summary',
    component: ClosureSummary
  },
  {
    path: '/participant-payments',
    name: 'participant-payments',
    component: ParticipantPayments
  },
  {
    path: '/request-history',
    name: 'request-history',
    component: RequestHistory
  },
  {
    path: '/view-request-history',
    name: 'view-request-history',
    component: ViewRequestHistory
  },
  {
    path: '/approve-request',
    name: 'approve-request',
    component: ApproveRequest
  },
  {
    path: '/approval-history',
    name: 'approval-history',
    component: ApprovalHistory
  },
  {
    path: '/view-approved-request',
    name: 'view-approved-request',
    component: ViewApprovedRequest
  },
  {
    path: '/pending-liquidation',
    name: 'pending-liquidation',
    component: PendingLiquidation
  },
  {
    path: '/approve-liquidation',
    name: 'approve-liquidation',
    component: ApproveLiquidation
  },
  {
    path: '/liquidation-approval-history',
    name: 'liquidation-approval-history',
    component: LiquidationApprovalHistory
  },
  {
    path: '/liquidation-history',
    name: 'liquidation-history',
    component: LiquidationHistory
  },
  {
    path: '/view-liquidation-history',
    name: 'view-liquidation-history',
    component: ViewLiquidationHistory
  },
  {
    path: '/view-liquidation-approval',
    name: 'view-liquidation-approval',
    component: ViewLiquidationApproval
  },
  {
    path: '/liquidation-timeline',
    name: 'liquidation-timeline',
    component: LiquidationTimeline
  },
  {
    path: '/signature',
    name: 'signature',
    component: Signature
  },
  {
    path: '/edit-memo',
    name: 'edit-memo',
    component: EditMemo
  },
  {
    path: '/edit-budget',
    name: 'edit-budget',
    component: EditBudget
  },
  {
    path: '/edit-participant-payments',
    name: 'edit-participant-payments',
    component: EditParticipantPayments
  },
  {
    path: '/edit-schedule',
    name: 'edit-schedule',
    component: EditSchedule
  },
  {
    path: '/edit-attachment',
    name: 'edit-attachment',
    component: EditAttachment
  },
  {
    path: '/edit-request-summary',
    name: 'edit-request-summary',
    component: EditRequestSummary
  },
  {
    path: '/edit-activity-closure',
    name: 'edit-activity-closure',
    component: EditActivityClosure
  },
  {
    path: '/edit-expense-report',
    name: 'edit-expense-report',
    component: EditExpenseReport
  },
  {
    path: '/edit-liquidation-attachment',
    name: 'edit-liquidation-attachment',
    component: EditLiquidationAttachment
  },
  {
    path: '/edit-closure-summary',
    name: 'edit-closure-summary',
    component: EditClosureSummary
  },
  {
    path: '/admin-home',
    name: 'admin-home',
    component: AdminHome
  },
  {
    path: '/funding-management',
    name: 'funding-management',
    component: FundingManagement
  },
  {
    path: '/stakeholder-management',
    name: 'stakeholder-management',
    component: StakeholderManagement
  },
  {
    path: '/per-diem-management',
    name: 'per-diem-management',
    component: PerDiemManagement
  },
  {
    path: '/employee-management',
    name: 'employee-management',
    component: EmployeeManagement
  },
  {
    path: '/deactivated-accounts',
    name: 'deactivated-accounts',
    component: DeactivatedAccounts
  },
  {
    path: '/delegate-approvals',
    name: 'delegate-approvals',
    component: DelegateApprovals
  },
  {
    path: '/position-management',
    name: 'position-management',
    component: PositionManagement
  },
  {
    path: '/employee-banking',
    name: 'employee-banking',
    component: EmployeeBanking
  },
  {
    path: '/other-participants',
    name: 'other-participants',
    component: OtherParticipants
  },
  {
    path: '/office-management',
    name: 'office-management',
    component: OfficeManagement
  },
  {
    path: '/venue-management',
    name: 'venue-management',
    component: VenueManagement
  },
  {
    path: '/employee-requests',
    name: 'employee-requests',
    component: EmployeeRequests
  },
  {
    path: '/admin-view-request',
    name: 'admin-view-request',
    component: AdminViewRequest
  },
  {
    path: '/download-requests',
    name: 'download-requests',
    component: DownloadRequests
  },
  {
    path: '/unsubmitted-requests',
    name: 'unsubmitted-requests',
    component: UnsubmittedRequests
  },
  {
    path: '/goal-management',
    name: 'goal-management',
    component: GoalManagement
  },
  {
    path: '/program-management',
    name: 'program-management',
    component: ProgramManagement
  },
  {
    path: '/main-objectives',
    name: 'main-objectives',
    component: MainObjectives
  },
  {
    path: '/specific-objectives',
    name: 'specific-objectives',
    component: SpecificObjectives
  },
  {
    path: '/pivot-year',
    name: 'pivot-year',
    component: PivotYear
  },
  {
    path: '/activity-type',
    name: 'activity-type',
    component: ActivityType
  },
  {
    path: '/request-type',
    name: 'request-type',
    component: RequestType
  },
  {
    path: '/delegation-reasons',
    name: 'delegation-reasons',
    component: DelegationReasons
  },
  {
    path: '/strategy-management',
    name: 'strategy-management',
    component: StrategyManagement
  },
  {
    path: '/employee-liquidation',
    name: 'employee-liquidation',
    component: EmployeeLiquidation
  },
  {
    path: '/admin-view-liquidation',
    name: 'admin-view-liquidation',
    component: AdminViewLiquidation
  },
  {
    path: '/download-liquidation',
    name: 'download-liquidation',
    component: DownloadLiquidation
  },
  {
    path: '/unsubmitted-liquidations',
    name: 'unsubmitted-liquidations',
    component: UnsubmittedLiquidation
  },
  {
    path: '/supervisor-management',
    name: 'supervisor-management',
    component: SupervisorManagement
  },
  {
    path: '/approved-request',
    name: 'approved-request',
    component: ApprovedRequest
  },
  {
    path: '/approved-liquidation',
    name: 'approved-liquidation',
    component: ApprovedLiquidation
  },
  {
    path: '/request-timeline',
    name: 'request-timeline',
    component: RequestTimeline
  },
  {
    path: '/admin-liquidation-timeline',
    name: 'admin-liquidation-timeline',
    component: AdminLiquidationTimeline
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  meta: {
    reload: true
  }
})

export default router
