<template>
  <div>
    <EmployeeRequests/>
  </div>
</template>
  
  <script>
  // @ is an alias to /src
  import EmployeeRequests from '@/components/EmployeeRequests.vue'
  
  export default {
    name: 'penidng-approval-view',
    components: {
      EmployeeRequests
    }
  }
  </script>
  