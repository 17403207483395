<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> >My Profile</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                  <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                </svg>
                <p class="text-left text-size-md font-medium text-gray-600">My Profile Details</p>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-2 gap-x-4 gap-y-6 sm:gap-y-6  ">
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Firstname <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="firstname"
                      placeholder="Firstname"
                      required
                      disabled
                      type="text"
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Surname <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="surname"
                      placeholder="Surname"
                      required
                      disabled
                      type="text"
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Email <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="email"
                      placeholder="Email"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Phone Number <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="phone"
                      placeholder="Phone Number"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Bank Number <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="accountNo"
                      placeholder="Account Number"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Bank Branch Code <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="branchCode"
                      placeholder="Bank Branch Code"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Position <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="positionName"
                      placeholder="Position"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Department <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="departmentName"
                      placeholder="Position"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Office Location <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="officeName"
                      placeholder="Office Location"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">District <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="officeName"
                      placeholder="Office Location"
                      required
                      disabled
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-400"/>
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 mb-10">
                <div class="flex-1 mb-10 hidden">
                  <button
                    @click="next()"
                    class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight hover:scale-105 transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                      Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { retry } from 'rxjs'
  
  export default {
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token'
      ])
    },
    components: {
      Navbar,
      Sidebar
    },
    setup () {
      return {
      }
    },
    name: 'profile-component',
    props: {
    },
    data: () => {
      return {
        firstname: '',
        surname: '',
        email: '',
        phone: '',
        accountNo: '',
        branchCode: '',
        positionName: '',
        departmentName: '',
        officeName: '',
        position: {},
        company: {},
        location: {},
        showProfile: false,
        employeeData: null,
        expandBody: false
      }
    },
    mounted () {
      // removing the submenu items selection
      this.$store.commit('mutateActiveListItemIndex', 5)
      this.$store.commit('mutateSelectedSubMenuItem', 3)
      // get the employee details
      this.getEmployee()
    },
    methods: {
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      },
      // getting the employee details
      getEmployee () {
        this.showProfile = false
        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/employee/findById?id=' + this.id,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            const data = response.response
            this.employeeData = data[0]
            this.firstname = this.employeeData.firstname
            this.surname = this.employeeData.surname
            this.email = this.employeeData.email
            this.phone = this.employeeData.phone
            this.accountNo = this.employeeData.bank_account
            this.branchCode = this.employeeData.bank_branch_code
            this.positionName = this.employeeData.position_name
            this.departmentName = this.employeeData.department_name
            this.officeName = this.employeeData.office_name
            // setting the position
            const positionId = this.employeeData.position_id
            const positionName = this.employeeData.position_name
            this.position = { id: positionId, name: positionName }
            // setting the company
            const companyId = this.employeeData.company_id
            const companyName = this.employeeData.company_name
            this.company = { id: companyId, name: companyName }
            // setting the location
            const locationId = this.employeeData.location_id
            const locationName = this.employeeData.district_name + ', ' + this.employeeData.country_name
            this.location = { id: locationId, name: locationName }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {
            this.showProfile = true
          }
        }
        observable.subscribe(observer)
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  