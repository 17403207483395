<template>
    <div>
      <EditSchedule/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EditSchedule from '@/components/EditSchedule.vue'
  
  export default {
    name: 'create-schedule-view',
    components: {
      EditSchedule
    }
  }
  </script>
  