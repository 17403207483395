<template>
    <div class="row-span-full fixed h-screen transform bg-white shadow-2xl border-r opacity-100 z-10" style="width: 19rem;">
          <!-- the side nav -->
          <nav class="text-left mx-5 mt-4 flex flex-col space-y-4">
            <!-- Home list item -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==0 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(0)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <svg :class="[activeListItemIndex==0 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19,15h-1c-.553,0-1-.447-1-1s.447-1,1-1h1c.553,0,1,.447,1,1s-.447,1-1,1Zm1,3c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm-4-12c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,0c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm0,4c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,9V5c0-2.757-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5,0,.553,.447,1,1,1s1-.447,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3v14c0,1.654-1.346,3-3,3h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c2.757,0,5-2.243,5-5Zm-8,.5v-4.152c0-1.548-.699-2.982-1.919-3.938l-3-2.349c-1.814-1.418-4.348-1.419-6.162,0l-3,2.348c-1.22,.955-1.919,2.39-1.919,3.938v4.152c0,2.481,2.019,4.5,4.5,4.5h7c2.481,0,4.5-2.019,4.5-4.5Zm-6.151-8.863l3,2.348c.731,.573,1.151,1.435,1.151,2.363v4.152c0,1.379-1.121,2.5-2.5,2.5H4.5c-1.379,0-2.5-1.121-2.5-2.5v-4.152c0-.929,.42-1.79,1.151-2.363l3-2.347c.544-.426,1.196-.639,1.849-.639s1.305,.213,1.849,.638Zm.151,7.363v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Z"/></svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==0 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Home</span>
                </div>
              </a>
            </div>
            <!-- Funding Management -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==1 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(1)">
              <a href="">
                <div class="flex items-center">
                  <div class="flex space-x-2">
                    <!-- <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg> -->
                    <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14.648,5.493c.873-.701,1.772-1.643,2.228-2.789,.238-.598,.161-1.277-.205-1.816-.377-.556-1.002-.888-1.671-.888h-6c-.669,0-1.294,.332-1.671,.888-.366,.539-.442,1.218-.205,1.816,.456,1.145,1.355,2.088,2.228,2.789C4.696,7.221,1,13.159,1,18c0,3.309,2.691,6,6,6h10c3.309,0,6-2.691,6-6,0-4.841-3.696-10.779-8.352-12.507Zm.369-3.528c-.516,1.297-2.094,2.393-3.019,2.91-.923-.513-2.495-1.6-2.999-2.875l6.018-.035Zm1.982,20.035H7c-2.206,0-4-1.794-4-4,0-5.243,4.71-11,9-11s9,5.757,9,11c0,2.206-1.794,4-4,4Zm-5,0c-.552,0-1-.448-1-1v-1h-.268c-1.068,0-2.063-.574-2.598-1.499-.276-.478-.113-1.089,.365-1.366,.476-.277,1.089-.114,1.366,.365,.178,.308,.511,.5,.867,.5h2.268c.551,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.042-.507c-1.341-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-1c0-.552,.448-1,1-1s1,.448,1,1v1h.268c1.067,0,2.063,.575,2.598,1.5,.276,.478,.113,1.089-.365,1.366-.477,.277-1.089,.114-1.366-.365-.179-.309-.511-.5-.867-.5h-2.268c-.551,0-1,.449-1,1,0,.378,.271,.698,.644,.76l3.042,.507c1.341,.223,2.315,1.373,2.315,2.733,0,1.654-1.346,3-3,3v1c0,.552-.448,1-1,1Z"/></svg>

                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==1 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Funding Management</span>
                  </div>
                </div>
              </a>
            </div>
            <!-- Stakeholder Management -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==2 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(2)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <!-- <svg :class="[activeListItemIndex==3 ? 'h-5 w-5 text-emerald-500 fill-current' : 'h-5 w-5 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,20c0,2.206-1.794,4-4,4H7.854l2.045-2h10.102c1.103,0,2-.897,2-2s-.897-2-2-2h-5c-2.206,0-4-1.794-4-4,0-1.927,1.37-3.539,3.187-3.916l1.959,1.916h-1.146c-1.103,0-2,.897-2,2s.897,2,2,2h5c2.206,0,4,1.794,4,4Zm-1.465-11.464c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878ZM8.535,20.536c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878Z"/></svg> -->
                  <svg :class="[activeListItemIndex==2 ? 'h-5 w-5 text-emerald-500 fill-current' : 'h-5 w-5 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="m14,10h2v14H0V3C0,1.346,1.346,0,3,0h2v2h-2c-.551,0-1,.448-1,1v19h12v-12Zm-7,0h-3v2h3v-2Zm5,0h-3v2h3v-2Zm-5,4h-3v2h3v-2Zm5,0h-3v2h3v-2Zm-8,6h3v-2h-3v2Zm5,0h3v-2h-3v2ZM21.5,1.6c.496,0,.9.404.9.9v3c0,.496-.404.9-.9.9s-.9-.404-.9-.9v-3c0-.496.404-.9.9-.9m0-1.6c-1.381,0-2.5,1.119-2.5,2.5v3c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5v-3c0-1.381-1.119-2.5-2.5-2.5h0Zm-6,3.5v1.5h.9v.5c0,.496-.404.9-.9.9s-.9-.404-.9-.9v-3c0-.496.404-.9.9-.9.307,0,.565.164.728.4h1.722c-.232-1.141-1.24-2-2.45-2-1.381,0-2.5,1.119-2.5,2.5v3c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5v-2h-2.5ZM10.4,0v4.063L8.6.009v-.009h-1.6v8h1.6V3.946l1.8,4.054h1.6V0h-1.6Z"/>
                  </svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==2 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Stakeholder Management</span>
                </div>
              </a>
            </div>
            <!-- Per Diem Management -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==3 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(3)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <!-- <svg :class="[activeListItemIndex==3 ? 'h-5 w-5 text-emerald-500 fill-current' : 'h-5 w-5 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,20c0,2.206-1.794,4-4,4H7.854l2.045-2h10.102c1.103,0,2-.897,2-2s-.897-2-2-2h-5c-2.206,0-4-1.794-4-4,0-1.927,1.37-3.539,3.187-3.916l1.959,1.916h-1.146c-1.103,0-2,.897-2,2s.897,2,2,2h5c2.206,0,4,1.794,4,4Zm-1.465-11.464c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878ZM8.535,20.536c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878Z"/></svg> -->
                  <svg :class="[activeListItemIndex==3 ? 'h-5 w-5 text-emerald-500 fill-current' : 'h-5 w-5 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="m2,6V1c0-.553.448-1,1-1s1,.447,1,1v5c0,.552.449,1,1,1h6c.551,0,1-.448,1-1V1c0-.553.448-1,1-1s1,.447,1,1v5c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3Zm5.962-3c1.311,0,2.589-.841,2.997-2.013.168-.481-.196-.987-.706-.987h-4.506c-.51,0-.873.505-.706.987.408,1.172,1.609,2.013,2.92,2.013Zm15.275,10.015l-6.805,7.638c-1.896,2.128-4.618,3.348-7.466,3.348h-4.965c-2.206,0-4-1.794-4-4v-5c0-2.206,1.794-4,4-4h8.858c1.139,0,2.138.609,2.689,1.519l3.216-3.534c.542-.595,1.282-.943,2.086-.981.808-.034,1.574.24,2.168.782,1.213,1.105,1.312,3.003.219,4.229Zm-1.566-2.751c-.2-.182-.458-.278-.728-.263-.27.013-.519.13-.7.329l-4.426,4.864c-.385,1.07-1.339,1.891-2.515,2.059l-5.16.737c-.545.08-1.053-.301-1.131-.849-.078-.547.302-1.054.848-1.132l5.161-.737c.56-.079.981-.565.981-1.13,0-.63-.512-1.143-1.142-1.143H4c-1.103,0-2,.897-2,2v5c0,1.103.897,2,2,2h4.965c2.279,0,4.456-.977,5.973-2.678l6.805-7.638c.367-.412.334-1.05-.073-1.421Z"/>
                  </svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==3 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Per Diem Management</span>
                </div>
              </a>
            </div>



            
            <!-- Account Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==4 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color: #42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==4 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==4 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Account Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==4" class="w-3 h-3 text-emerald-500 fill-current mx-10" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-10" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-2 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Account Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==1 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(4)">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-current" id="Outline" viewBox="0 0 24 24" width="512" height="512"><circle cx="7" cy="22" r="2"/><circle cx="17" cy="22" r="2"/><path d="M23,3H21V1a1,1,0,0,0-2,0V3H17a1,1,0,0,0,0,2h2V7a1,1,0,0,0,2,0V5h2a1,1,0,0,0,0-2Z"/><path d="M21.771,9.726a.994.994,0,0,0-1.162.806A3,3,0,0,1,17.657,13H5.418l-.94-8H13a1,1,0,0,0,0-2H4.242L4.2,2.648A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.829-2H17.657a5,5,0,0,0,4.921-4.112A1,1,0,0,0,21.771,9.726Z"/></svg> -->
                      <!-- <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A11.972,11.972,0,0,0,4,3.073V1A1,1,0,0,0,2,1V4A3,3,0,0,0,5,7H8A1,1,0,0,0,8,5H5a.854.854,0,0,1-.1-.021A9.987,9.987,0,1,1,2,12a1,1,0,0,0-2,0A12,12,0,1,0,12,0Z"/><path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==1 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m15.5 13.5h-2.19a1.991 1.991 0 0 1 -2.81-2.81v-3.69a1.5 1.5 0 0 1 3 0v3.5h2a1.5 1.5 0 0 1 0 3zm-3.5-13.5a1.5 1.5 0 0 0 0 3 9.01 9.01 0 0 1 9 9 1.5 1.5 0 0 0 3 0 12.013 12.013 0 0 0 -12-12zm-9 12.5a1.5 1.5 0 1 0 -1.5 1.5 1.5 1.5 0 0 0 1.5-1.5zm3.74-10.606a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm-3.827 3.856a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm3.827 14.356a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm-3.827-3.856a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm9.087 4.75a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm9.106-5.24a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5zm-3.856 3.827a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==1 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m6,1c0-.553.448-1,1-1h10c.552,0,1,.447,1,1s-.448,1-1,1H7c-.552,0-1-.447-1-1Zm-2,6h16c.552,0,1-.447,1-1s-.448-1-1-1H4c-.552,0-1,.447-1,1s.448,1,1,1Zm15.5,9h-1.5v-1.5c0-.553-.448-1-1-1s-1,.447-1,1v1.5h-1.5c-.552,0-1,.447-1,1s.448,1,1,1h1.5v1.5c0,.553.448,1,1,1s1-.447,1-1v-1.5h1.5c.552,0,1-.447,1-1s-.448-1-1-1Zm4.5,1c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-13,5h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c.552,0,1-.447,1-1s-.448-1-1-1h-4c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h4c.552,0,1-.447,1-1s-.448-1-1-1Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Employee Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==2 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(5)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg>
                      <span class="text-size-sm text-gray-700">Deactivated Accounts</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==3 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(6)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,17h6v2h-6v-2Zm0-4v2h2v-2h-2Zm6-9h-12v3.159c.716.113,1.387.284,2,.511v-1.67h8v2h-7.233c1.045.508,1.878,1.189,2.437,2h6.796v-6Zm1-4H7c-1.657,0-3,1.343-3,3v4.159c.634-.1,1.3-.159,2-.159V3c0-.552.448-1,1-1h14c.552,0,1,.448,1,1v19h-8.275c-.278.752-.76,1.428-1.417,2h11.692V3c0-1.657-1.343-3-3-3Zm-1,13h-2v2h2v-2Zm-8-.5v8c0,1.995-2.579,3.5-6,3.5s-6-1.505-6-3.5v-8c0-1.995,2.579-3.5,6-3.5s6,1.505,6,3.5Zm-2,8v-1.348c-1.046.533-2.435.848-4,.848s-2.954-.315-4-.848v1.348c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm0-4v-1.348c-1.046.533-2.435.848-4,.848s-2.954-.315-4-.848v1.348c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm0-4c0-.529-1.519-1.5-4-1.5s-4,.971-4,1.5,1.519,1.5,4,1.5,4-.971,4-1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Delegate Approvals</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==4 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(7)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==4 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m12,10c2.757,0,5-2.243,5-5S14.757,0,12,0s-5,2.243-5,5,2.243,5,5,5Zm0-8c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Zm11,17h-3c0-4.411-3.589-8-8-8s-8,3.589-8,8H1c-.552,0-1,.448-1,1s.448,1,1,1h1v2c0,.552.448,1,1,1s1-.448,1-1v-2h16v2c0,.552.448,1,1,1s1-.448,1-1v-2h1c.552,0,1-.448,1-1s-.448-1-1-1Zm-17,0c0-2.573,1.631-4.767,3.911-5.617l1.187,1.781-1.099,3.836h-4Zm6.901-3.836l1.187-1.781c2.28.851,3.911,3.044,3.911,5.617h-4l-1.099-3.836Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Position Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==5 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(8)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==5 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m0 22h24v2h-24zm24-14.091v2.091h-3v8h2v2h-22v-2h2v-8h-3v-2.091a3 3 0 0 1 1.563-2.634l9-4.909a2.993 2.993 0 0 1 2.874 0l9 4.909a3 3 0 0 1 1.563 2.634zm-19 10.091h3v-8h-3zm5-8v8h4v-8zm9 0h-3v8h3zm3-2.091a1 1 0 0 0 -.521-.878l-9-4.909a1 1 0 0 0 -.958 0l-9 4.909a1 1 0 0 0 -.521.878v.091h20z"/></svg>
                      <span class="text-size-sm text-gray-700">Employee Banking Details</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==6 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(9)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m0 22h24v2h-24zm24-14.091v2.091h-3v8h2v2h-22v-2h2v-8h-3v-2.091a3 3 0 0 1 1.563-2.634l9-4.909a2.993 2.993 0 0 1 2.874 0l9 4.909a3 3 0 0 1 1.563 2.634zm-19 10.091h3v-8h-3zm5-8v8h4v-8zm9 0h-3v8h3zm3-2.091a1 1 0 0 0 -.521-.878l-9-4.909a1 1 0 0 0 -.958 0l-9 4.909a1 1 0 0 0 -.521.878v.091h20z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m10.988,9.116c.292.259.658.387,1.021.387.354,0,.705-.123.977-.365l1.375-1.138h2.688V2.048c0-1.129-.918-2.048-2.048-2.048h-5.905c-1.129,0-2.047.918-2.047,2.048v5.952h2.592l1.348,1.116Zm-1.893-7.116l5.953.048v3.952h-1.408l-1.639,1.358-1.64-1.358h-1.303l.038-4Zm9.405,4.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5-2.5-1.119-2.5-2.5Zm-10.5,6.5h8v2h-8v-2Zm-2,0c0-1.654-1.346-3-3-3s-3,1.346-3,3v4c0,1.654,1.346,3,3,3h4v4h2v-6h-3v-5Zm-2,5h-1c-.551,0-1-.449-1-1v-4c0-.551.449-1,1-1s1,.449,1,1v5Zm15,4h5v2h-5v-2Zm2-12c-1.654,0-3,1.346-3,3v5h-3v6h2v-4h4c1.654,0,3-1.346,3-3v-4c0-1.654-1.346-3-3-3Zm1,7c0,.551-.449,1-1,1h-1v-5c0-.551.449-1,1-1s1,.449,1,1v4ZM0,22h5v2H0v-2ZM.5,6.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5S.5,7.881.5,6.5Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Other Participants</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==24 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(27)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m0 22h24v2h-24zm24-14.091v2.091h-3v8h2v2h-22v-2h2v-8h-3v-2.091a3 3 0 0 1 1.563-2.634l9-4.909a2.993 2.993 0 0 1 2.874 0l9 4.909a3 3 0 0 1 1.563 2.634zm-19 10.091h3v-8h-3zm5-8v8h4v-8zm9 0h-3v8h3zm3-2.091a1 1 0 0 0 -.521-.878l-9-4.909a1 1 0 0 0 -.958 0l-9 4.909a1 1 0 0 0 -.521.878v.091h20z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m10.988,9.116c.292.259.658.387,1.021.387.354,0,.705-.123.977-.365l1.375-1.138h2.688V2.048c0-1.129-.918-2.048-2.048-2.048h-5.905c-1.129,0-2.047.918-2.047,2.048v5.952h2.592l1.348,1.116Zm-1.893-7.116l5.953.048v3.952h-1.408l-1.639,1.358-1.64-1.358h-1.303l.038-4Zm9.405,4.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5-2.5-1.119-2.5-2.5Zm-10.5,6.5h8v2h-8v-2Zm-2,0c0-1.654-1.346-3-3-3s-3,1.346-3,3v4c0,1.654,1.346,3,3,3h4v4h2v-6h-3v-5Zm-2,5h-1c-.551,0-1-.449-1-1v-4c0-.551.449-1,1-1s1,.449,1,1v5Zm15,4h5v2h-5v-2Zm2-12c-1.654,0-3,1.346-3,3v5h-3v6h2v-4h4c1.654,0,3-1.346,3-3v-4c0-1.654-1.346-3-3-3Zm1,7c0,.551-.449,1-1,1h-1v-5c0-.551.449-1,1-1s1,.449,1,1v4ZM0,22h5v2H0v-2ZM.5,6.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5S.5,7.881.5,6.5Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==24 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m1.5,18c0-1.105.895-2,2-2s2,.895,2,2-.895,2-2,2-2-.895-2-2Zm8.5,0c0,1.105.895,2,2,2s2-.895,2-2-.895-2-2-2-2,.895-2,2Zm2-14c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm6.5,14c0,1.105.895,2,2,2s2-.895,2-2-.895-2-2-2-2,.895-2,2Zm-14-5.5c0-.275.224-.5.5-.5h6v2h2v-2h6c.276,0,.5.225.5.5v1.5h2v-1.5c0-1.379-1.122-2.5-2.5-2.5H5c-1.378,0-2.5,1.121-2.5,2.5v1.5h2v-1.5Zm.5,8.5h-3c-1.103,0-2,.897-2,2v1h2v-1h3v1h2v-1c0-1.103-.897-2-2-2Zm17,0h-3c-1.103,0-2,.897-2,2v1h2v-1h3v1h2v-1c0-1.103-.897-2-2-2Zm-8.5,0h-3c-1.103,0-2,.897-2,2v1h2v-1h3v1h2v-1c0-1.103-.897-2-2-2Zm-3-14h3v1h2v-1c0-1.103-.897-2-2-2h-3c-1.103,0-2,.897-2,2v1h2v-1Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Supervisor Management</span>
                    </div>

                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Liquidation Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==5 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m13,11h11v2h-11v11h-2v-11H0v-2h11V0h2v11Zm3,7h8v-2h-8v2Zm0,4h8v-2h-8v2ZM8,4H0v2h8v-2Zm13,0V1h-2v3h-3v2h3v3h2v-3h3v-2h-3ZM7.062,15.523l-2.562,2.562-2.562-2.562-1.414,1.414,2.562,2.562-2.562,2.562,1.414,1.414,2.562-2.562,2.562,2.562,1.414-1.414-2.562-2.562,2.562-2.562-1.414-1.414Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==5 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Liquidation Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==5" class="w-3 h-3 text-emerald-500 fill-current mx-2" style="margin-left: 1.5rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-2" style="margin-left: 1.5rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Liquidation Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==7 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(10)">
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Employee Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==8 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(11)">
                      <svg :class="[selectedSubMenuItem==8 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.95,5.54l-3.49-3.49c-1.32-1.32-3.08-2.05-4.95-2.05H7C4.24,0,2,2.24,2,5v14c0,2.76,2.24,5,5,5h10c2.76,0,5-2.24,5-5V10.49c0-1.87-.73-3.63-2.05-4.95Zm-1.41,1.41c.32,.32,.59,.67,.81,1.05h-4.34c-.55,0-1-.45-1-1V2.66c.38,.22,.73,.49,1.05,.81l3.49,3.49Zm1.46,12.05c0,1.65-1.35,3-3,3H7c-1.65,0-3-1.35-3-3V5c0-1.65,1.35-3,3-3h4.51c.16,0,.33,0,.49,.02V7c0,1.65,1.35,3,3,3h4.98c.02,.16,.02,.32,.02,.49v8.51ZM7.09,13h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.62,.62,.62s.62-.28,.62-.62v-1.22h.84c1.18,0,2.14-.95,2.14-2.11s-.96-2.11-2.14-2.11Zm0,2.97h-.83s0-1.72,0-1.72h.84c.48,0,.89,.39,.89,.86s-.41,.86-.89,.86Zm11.93-2.34c0,.35-.28,.62-.62,.62h-1.69v1.14h1.24c.35,0,.62,.28,.62,.62s-.28,.62-.62,.62h-1.24v1.8c0,.35-.28,.62-.62,.62s-.62-.28-.62-.62v-4.81c0-.35,.28-.62,.62-.62h2.31c.35,0,.62,.28,.62,.62Zm-6.93-.62h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.56,.62,.56s1.46,0,1.46,0c1.18,0,2.14-.95,2.14-2.11v-1.78c0-1.16-.96-2.11-2.14-2.11Zm.89,3.89c0,.47-.41,.86-.89,.86h-.83s0-3.5,0-3.5h.84c.48,0,.89,.39,.89,.86v1.78Z"/></svg>
                      <span class="text-size-sm text-center">Download Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==9 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(12)">
                      <svg :class="[selectedSubMenuItem==9 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg>
                      <span class="text-size-sm text-center">Unliquidated Requests</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Location Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==6 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==6 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m1.886,8.437c-1.328.484-2.085,1.795-1.841,3.189.245,1.397,1.407,2.374,2.826,2.374h7.129v7.128c0,1.419.976,2.582,2.374,2.827.175.03.349.046.521.046,1.192,0,2.249-.727,2.666-1.878L24.028-.014,1.886,8.437Zm11.801,12.988c-.229.635-.797.59-.967.561-.169-.03-.72-.18-.72-.857v-9.128H2.871c-.678,0-.826-.551-.856-.72-.029-.168-.076-.736.561-.967L20.562,3.449l-6.875,17.976Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==6 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Location Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==6" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 2.3rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 2.3rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Location Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==10 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(13)">
                      <!-- -->
                      <svg :class="[selectedSubMenuItem==6 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Office Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==11 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(14)">
                      <!-- -->
                      <svg :class="[selectedSubMenuItem==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Venues & Facilities</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Request Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==7 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <svg :class="[activeListItemIndex==7 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==7 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Request Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==7" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 2.4rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 2.4rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Request Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==12 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(15)">
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==12 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Employee Requests</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==13 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(16)">
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==13 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Download Requests</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==14 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(17)">
                      <svg :class="[selectedSubMenuItem==14 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg>
                      <span class="text-size-sm text-center">Unsubmitted Requests</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>


            <!-- Workplan Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==8 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg :class="[activeListItemIndex==8 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg> -->
                    <svg :class="[activeListItemIndex==8 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m17.163,13c.434,1.441,1.757,2.5,3.337,2.5,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5c-1.58,0-2.903,1.059-3.337,2.5h-1.163v-6c0-.275.225-.5.5-.5h.663c.434,1.441,1.757,2.5,3.337,2.5,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5c-1.58,0-2.903,1.059-3.337,2.5h-.663c-1.379,0-2.5,1.121-2.5,2.5v6h-2v2h2v6c0,1.379,1.121,2.5,2.5,2.5h.663c.434,1.441,1.757,2.5,3.337,2.5,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5c-1.58,0-2.903,1.059-3.337,2.5h-.663c-.275,0-.5-.225-.5-.5v-6h1.163Zm3.337-2.5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm0-8.5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm0,17c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm-9.8-10.861l-1.411.813c-.605-.652-1.393-1.126-2.29-1.331v-1.621h-2v1.621c-.896.205-1.685.679-2.29,1.331l-1.411-.813-.998,1.732,1.416.816c-.129.418-.218.853-.218,1.313s.089.895.218,1.313l-1.416.816.998,1.732,1.411-.813c.605.652,1.393,1.126,2.29,1.331v1.621h2v-1.621c.896-.205,1.685-.679,2.29-1.331l1.411.813.998-1.732-1.416-.816c.129-.418.218-.853.218-1.313s-.089-.895-.218-1.313l1.416-.816-.998-1.732Zm-4.7,6.361c-1.379,0-2.5-1.121-2.5-2.5s1.121-2.5,2.5-2.5,2.5,1.121,2.5,2.5-1.121,2.5-2.5,2.5Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==8 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Workplan Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==8" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 1.8rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 1.8rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-72
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Workplan Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==15 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(18)">
                      <!-- <svg :class="[selectedSubMenuItem==15 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m15 3a3 3 0 0 0 -3-3h-12v24h2v-11h8v1a3 3 0 0 0 3 3h11v-13h-9zm-13-1h10a1 1 0 0 1 1 1v8h-11zm20 4v9h-9a1 1 0 0 1 -1-1v-1h3v-7z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==15 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m10,14.11l11,3.143v6.747h-3v-4.484l-8-2.286v6.769h-3l-4.266-3.391c-.866-.722-.983-2.008-.261-2.874.722-.866,2.008-.983,2.874-.261l1.64,1.222v-10.234c0-.911.799-1.632,1.739-1.479.737.12,1.273.812,1.273,1.56v5.57ZM24,3.5v7.5h-12v-2.421c0-.353-.064-.693-.162-1.019l.168-.13,1.63,1.261.378-.262-.663-2.015,1.648-.955v-.458h-2.163l-.588-2.251h-.486l-.588,2.251h-5.338l-.588-2.251h-.486l-.588,2.251h-2.175v.462l1.66.924-.652,2.039.36.272,1.638-1.266.133.103c-.09.312-.152.633-.152.966v2.501H0V3.5C0,1.57,1.57,0,3.5,0h17c1.93,0,3.5,1.57,3.5,3.5Zm-2,1.5h-2.163l-.588-2.251h-.486l-.588,2.251h-2.175v.462l1.66.924-.652,2.039.36.272,1.638-1.266,1.63,1.261.378-.262-.663-2.015,1.648-.955v-.458Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Goals Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==16 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(19)">
                      <svg :class="[selectedSubMenuItem==16 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,1H3C1.346,1,0,2.346,0,4v19h24V4c0-1.654-1.346-3-3-3Zm1,20H2V4c0-.551.449-1,1-1h18c.551,0,1,.449,1,1v17ZM10,6h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Program Management</span>
                    </div>

                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==17 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(20)">
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==3 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==17 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512" height="512">
                        <g>
                          <path d="M309.333,149.333H480c17.673,0,32-14.327,32-32s-14.327-32-32-32H309.333c-17.673,0-32,14.327-32,32   S291.66,149.333,309.333,149.333z"/>
                          <path d="M117.333,0C52.532,0,0,52.532,0,117.333s52.532,117.333,117.333,117.333s117.333-52.532,117.333-117.333   C234.596,52.561,182.106,0.071,117.333,0z M117.333,170.667C87.878,170.667,64,146.789,64,117.333S87.878,64,117.333,64   s53.333,23.878,53.333,53.333S146.789,170.667,117.333,170.667z"/>
                          <path d="M117.333,277.333C52.532,277.333,0,329.865,0,394.667S52.532,512,117.333,512s117.333-52.532,117.333-117.333   C234.596,329.895,182.106,277.404,117.333,277.333z M117.333,448C87.878,448,64,424.122,64,394.667s23.878-53.333,53.333-53.333   s53.333,23.878,53.333,53.333S146.789,448,117.333,448z"/>
                          <path d="M394.667,277.333c-64.801,0-117.333,52.532-117.333,117.333S329.865,512,394.667,512S512,459.468,512,394.667   C511.929,329.895,459.439,277.404,394.667,277.333z M394.667,448c-29.455,0-53.333-23.878-53.333-53.333   s23.878-53.333,53.333-53.333S448,365.211,448,394.667S424.122,448,394.667,448z"/>
                        </g>
                      </svg>
                      <span class="text-size-sm text-center">Main Objectives</span>
                    </div>

                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==18 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(21)">
                      <!-- <svg :class="[selectedSubMenuItem==18 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==18 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8.5,0h-3.5C2.243,0,0,2.243,0,5v3.5c0,.829.671,1.5,1.5,1.5h7c.829,0,1.5-.671,1.5-1.5V1.5c0-.829-.671-1.5-1.5-1.5Zm-1.5,7H3v-2c0-1.103.897-2,2-2h2v4Zm6.5,3h7c.829,0,1.5-.671,1.5-1.5v-3.5c0-2.757-2.243-5-5-5h-3.5c-.829,0-1.5.671-1.5,1.5v7c0,.829.671,1.5,1.5,1.5Zm1.5-7h2c1.103,0,2,.897,2,2v2h-4V3Zm-6.5,9H1.5c-.829,0-1.5.671-1.5,1.5v3.5c0,2.757,2.243,5,5,5h3.5c.829,0,1.5-.671,1.5-1.5v-7c0-.829-.671-1.5-1.5-1.5Zm-1.5,7h-2c-1.103,0-2-.897-2-2v-2h4v4Zm16.561,2.439l-2.145-2.145c.361-.691.584-1.463.584-2.295,0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5c.831,0,1.604-.223,2.295-.584l2.145,2.145c.293.293.677.439,1.061.439s.768-.146,1.061-.439c.586-.585.586-1.536,0-2.121Zm-6.561-2.439c-1.103,0-2-.897-2-2s.897-2,2-2,2,.897,2,2-.897,2-2,2Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==18 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M11,13.5v-4c0-.828,.672-1.5,1.5-1.5s1.5,.672,1.5,1.5v4c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5Zm5.5,1.5c.828,0,1.5-.672,1.5-1.5v-4c0-.828-.672-1.5-1.5-1.5s-1.5,.672-1.5,1.5v4c0,.828,.672,1.5,1.5,1.5ZM6,7v1.415c1.01-.247,2.085-.101,3,.436V4H1l2,1.5-2,1.5H6ZM9,1.5c0-.828-.672-1.5-1.5-1.5s-1.5,.672-1.5,1.5v.5h3v-.5Zm11.5,6.5c-.828,0-1.5,.672-1.5,1.5v4c0,.828,.672,1.5,1.5,1.5s1.5-.672,1.5-1.5v-4c0-.828-.672-1.5-1.5-1.5Zm-1.5,8.649v4.351H8.093l-3.505-3.288,3.814-3.948c.797-.797,.797-2.089,0-2.886-.797-.797-2.089-.797-2.886,0l-3.516,3.609,.002,.006c-.646,.658-1.002,1.527-1.002,2.452,0,.935,.364,1.814,1.06,2.508l4.847,4.547h15.093v-7.351c-.456,.219-.961,.351-1.5,.351s-1.044-.133-1.5-.351Z"/></svg>
                      <span class="text-size-sm text-center">Specific Objectives</span>
                    </div>

                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==19 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(22)">
                      <!-- <svg :class="[selectedSubMenuItem==19 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==19 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M21,2h-3V0h-2V2H8V0h-2V2H3C1.346,2,0,3.346,0,5V24H24V5c0-1.654-1.346-3-3-3ZM3,4H21c.552,0,1,.449,1,1v3H2v-3c0-.551,.448-1,1-1Zm-1,18V10H22v12H2Zm3-9h14v2H5v-2Zm0,4h7v2H5v-2Z"/></svg>
                      <span class="text-size-sm text-center">Pivot Year</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==20 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(23)">
                      <!-- <svg :class="[selectedSubMenuItem==20 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==20 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m5.5,11c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5Zm0-5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm10,1c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5Zm0-5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm1.5,8c-3.859,0-7,3.141-7,7s3.141,7,7,7,7-3.141,7-7-3.141-7-7-7Zm0,12c-2.757,0-5-2.243-5-5s2.243-5,5-5,5,2.243,5,5-2.243,5-5,5Zm3.991-5.882l-3.456,3.399c-.308.309-.727.482-1.173.482s-.864-.174-1.179-.489l-1.845-1.788,1.393-1.436,1.63,1.58,3.228-3.175,1.402,1.426Zm-12.991-4.118c.477,0,.922.122,1.323.321-.347.567-.634,1.174-.849,1.815-.143-.079-.299-.136-.473-.136H3c-.552,0-1,.448-1,1v3H0v-3c0-1.654,1.346-3,3-3h5Zm2-.651v-.349c0-1.654,1.346-3,3-3h4c-2.829,0-5.35,1.308-7,3.349Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Activity Types</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==21 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(24)">
                      <!-- <svg :class="[selectedSubMenuItem==21 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==21 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m23.907,21.786l-2.742-2.742c.527-.893.836-1.931.836-3.043,0-3.314-2.686-6-6-6s-6,2.686-6,6,2.686,6,6,6c1.112,0,2.15-.308,3.043-.836l2.742,2.742,2.121-2.121Zm-7.158-3.342c-.744.744-2.058.746-2.823-.019l-2.182-2.268,1.387-1.441,2.216,2.301,3.614-3.703,1.398,1.43-3.607,3.696-.004.004Zm-.828,5.556L0,23.993v-1.5s0-19.5,0-19.5C0,1.339,1.346-.007,3-.007h11.381l5.619,5.665v3.413c-.903-.523-1.918-.936-3-1.071v-.007h-5V2.993H3v18.001l6.753.003c1.451,1.811,3.673,2.978,6.168,3.002Zm-7.859-8.999c-.041.328-.062.661-.062,1,0,.691.088,1.361.252,2h-3.252v-3h3.062Zm.52-2h-3.582v-3h5.709c-.924.815-1.657,1.84-2.127,3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Request Types</span>
                    </div>

                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==22 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(25)">
                      <!-- <svg :class="[selectedSubMenuItem==22 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==22 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m22.732,9.013c-.767-.699-1.766-1.06-2.797-1.008-1.036.048-1.991.498-2.69,1.265l-2.438,2.679c-.646-.589-1.507-.949-2.449-.949H3.5c-1.93,0-3.5,1.57-3.5,3.5v6c0,1.93,1.57,3.5,3.5,3.5h11.181l8.334-9.532c1.41-1.581,1.283-4.027-.282-5.455Zm-1.966,3.469l-7.447,8.518H3.5c-.275,0-.5-.224-.5-.5v-6c0-.276.225-.5.5-.5h8.857c.354,0,.643.288.643.642,0,.317-.237.591-.551.636l-5.161.737.424,2.97,5.162-.737c1.618-.231,2.875-1.531,3.093-3.115l3.497-3.843c.159-.175.376-.277.612-.288.233-.006.463.071.636.229.356.325.385.881.055,1.252ZM3,4.5c-.024-1.627,1.023-3.326,3.011-3.942,3.371-1.493,8.988.206,8.988,4.442,0,3-2.309,4.798-4.549,5,1.517-.89,2.549-2.38,2.549-4,0-2-2.047-2.961-3.5-2.992-1.212-.025-3.5-.008-3.5,1.992,0,.842.906,1.5,2,1.5,1,0,2.068-.398,2.451-1.5.143.589-.451,3.027-3.451,3.027s-3.976-1.991-4-3.527Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Delegation Reasons</span>
                    </div>

                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==23 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(26)">
                      <!-- <svg :class="[selectedSubMenuItem==23 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==23 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m11.864,4.001c-4.184.069-7.709,3.526-7.858,7.705-.088,2.428.914,4.733,2.75,6.326.791.687,1.244,1.743,1.244,2.968,0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-.375c0-.966.455-1.898,1.282-2.626,1.728-1.518,2.718-3.704,2.718-5.999,0-2.161-.849-4.187-2.39-5.703-1.541-1.516-3.583-2.345-5.746-2.296Zm2.136,16.999c0,.552-.448,1-1,1h-2c-.552,0-1-.448-1-1.069,0-.316-.031-.626-.077-.931h4.118c-.025.206-.041.415-.041.625v.375Zm1.962-4.503c-.511.449-.923.957-1.24,1.503h-1.722v-4.184c1.161-.414,2-1.514,2-2.816,0-.553-.447-1-1-1s-1,.447-1,1-.448,1-1,1-1-.448-1-1-.447-1-1-1-1,.447-1,1c0,1.302.839,2.402,2,2.816v4.184h-1.746c-.31-.558-.707-1.06-1.188-1.478-1.376-1.195-2.128-2.924-2.062-4.744.112-3.134,2.756-5.726,5.894-5.777.034,0,.067,0,.102,0,1.586,0,3.077.609,4.208,1.723,1.156,1.137,1.793,2.656,1.793,4.277,0,1.72-.743,3.358-2.038,4.497Zm.823-14.023l1.235-2.01c.288-.472.904-.619,1.375-.328.471.289.618.904.328,1.375l-1.235,2.01c-.188.308-.517.477-.853.477-.179,0-.359-.048-.522-.148-.471-.289-.618-.904-.328-1.375Zm6.628,4.148l-1.933.872c-.133.061-.273.089-.41.089-.382,0-.745-.219-.912-.589-.228-.503-.004-1.096.5-1.322l1.933-.872c.506-.229,1.096-.003,1.322.5.228.503.004,1.096-.5,1.322ZM4.194,1.51c-.289-.471-.141-1.087.33-1.375.473-.288,1.087-.14,1.375.33l1.232,2.011c.289.471.141,1.087-.33,1.375-.163.1-.344.147-.521.147-.337,0-.665-.17-.854-.478l-1.232-2.011Zm-.483,5.551c-.171.359-.529.568-.902.568-.145,0-.292-.031-.431-.099l-1.798-.861c-.498-.238-.709-.835-.47-1.333.237-.499.837-.712,1.333-.47l1.798.861c.498.238.709.835.47,1.333Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Workplan Strategy</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>

            <!-- divider line -->
            <div class=" h-2 w-full border-t"></div>
            <div  class="px-4 color: #42b983"  @click="barClick(100)">
              <a href="#">
                <div class="flex space-x-2 items-center">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500 fill-current" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M15,2.426v2.1a9,9,0,1,1-6,0v-2.1a11,11,0,1,0,6,0Z"/><rect x="11" width="2" height="8"/></g></svg> -->
                  <svg class="h-4 w-4 text-gray-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="M12,7.505c.552,0,1-.448,1-1V2c0-.552-.448-1-1-1s-1,.448-1,1V6.505c0,.552,.448,1,1,1Z"/>
                    <path d="M16.875,2.396c-.523-.168-1.09,.117-1.26,.643-.171,.525,.117,1.089,.643,1.26,2.061,.668,4.425,2.191,4.425,8.078,0,7.157-3.471,8.624-8.683,8.624S3.317,19.533,3.317,12.376c0-5.87,2.366-7.397,4.427-8.072,.525-.171,.811-.736,.639-1.261-.171-.525-.738-.814-1.261-.639C3.216,3.682,1.317,6.944,1.317,12.376c0,9.417,5.709,10.624,10.683,10.624s10.683-1.208,10.683-10.624c0-5.447-1.899-8.712-5.808-9.98Z"/>
                  </svg>
                  <span class="font-medium text-size-sm  text-gray-600">Signout</span>
                </div>
              </a>
            </div>
          </nav>
        </div>
  </template>
  
  <script>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  
  export default {
    computed: {
      ...mapState([
        'activeListItemIndex',
        'selectedSubMenuItem',
        'email',
        'loginId',
        'token'
      ])
    },
    components: {
      Popover,
      PopoverButton,
      PopoverPanel
    },
    setup () {
      return {
      }
    },
    name: 'side-bar',
    props: {
    },
    data: () => {
      return {
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
    },
    methods: {
      // signout of the system
      // logs out users
      logout () {
        const observable = ajax({
          url: this.BASE_URL + '/login/signout',
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          body: {
            id: this.loginId
          }
        })

        const observer = {
          next: () => {
          },
          error: () => {
            this.$router.push('/')
          },
          complete: () => {
            this.$router.push('/')
          }
        }
  
        observable.subscribe(observer)
      },
      // side nav bar list items clicks
      barClick (index) {
        switch (index) {
          case 0:
            this.$router.push('/admin-home')
            this.$store.commit('mutateActiveListItemIndex', 0)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            break
          case 1:
            this.$store.commit('mutateActiveListItemIndex', 1)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/funding-management')
            break
          case 2:
            this.$store.commit('mutateActiveListItemIndex', 2)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/stakeholder-management')
            break
          case 3:
            this.$store.commit('mutateActiveListItemIndex', 3)
            this.$store.commit('mutateSelectedSubMenuItem', 3)
            this.$router.push('/per-diem-management')
            break
          case 4:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 1)
            this.$router.push('/employee-management')
            break
          case 5:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 2)
            this.$router.push('/deactivated-accounts')
            break
          case 6:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 3)
            this.$router.push('/delegate-approvals')
            break
          case 7:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 4)
            this.$router.push('/position-management')
            break

          case 8:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 5)
            this.$router.push('/employee-banking')
            break
          case 9:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 6)
            this.$router.push('/other-participants')
            break
          case 10:
            this.$store.commit('mutateActiveListItemIndex', 5)
            this.$store.commit('mutateSelectedSubMenuItem', 7)
            this.$router.push('/employee-liquidation')
            break
          case 11:
            this.$store.commit('mutateActiveListItemIndex', 5)
            this.$store.commit('mutateSelectedSubMenuItem', 8)
            this.$router.push('/download-liquidation')
            break
          case 12:
            this.$store.commit('mutateActiveListItemIndex', 5)
            this.$store.commit('mutateSelectedSubMenuItem', 9)
            this.$router.push('/unsubmitted-liquidations')
            break
          case 13:
            this.$store.commit('mutateActiveListItemIndex', 6)
            this.$store.commit('mutateSelectedSubMenuItem', 10)
            this.$router.push('/office-management')
            break
          case 14:
            this.$store.commit('mutateActiveListItemIndex', 6)
            this.$store.commit('mutateSelectedSubMenuItem', 11)
            this.$router.push('/venue-management')
            break
          case 15:
            this.$store.commit('mutateActiveListItemIndex', 7)
            this.$store.commit('mutateSelectedSubMenuItem', 12)
            this.$router.push('/employee-requests')
            break
          case 16:
            this.$store.commit('mutateActiveListItemIndex', 7)
            this.$store.commit('mutateSelectedSubMenuItem', 13)
            this.$router.push('/download-requests')
            break
          case 17:
            this.$store.commit('mutateActiveListItemIndex', 7)
            this.$store.commit('mutateSelectedSubMenuItem', 14)
            this.$router.push('/unsubmitted-requests')
            break
          case 18:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 15)
            this.$router.push('/goal-management')
            break
          case 19:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 16)
            this.$router.push('/program-management')
            break
          case 20:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 17)
            this.$router.push('/main-objectives')
            break
          case 21:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 18)
            this.$router.push('/specific-objectives')
            break
          case 22:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 19)
            this.$router.push('/pivot-year')
            break
          case 23:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 20)
            this.$router.push('/activity-type')
            break
          case 24:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 21)
            this.$router.push('/request-type')
            break
          case 25:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 22)
            this.$router.push('/delegation-reasons')
            break
          case 26:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 23)
            this.$router.push('/strategy-management')
            break
          case 27:
            this.$store.commit('mutateActiveListItemIndex', 4)
            this.$store.commit('mutateSelectedSubMenuItem', 24)
            this.$router.push('/supervisor-management')
            break

          default:
            // signout
            this.logout()
        }
      }
    }
  }
  </script>
  
  <style>
  </style>
  