<template>
  <div>
    <ApproveRequest/>
  </div>
</template>

<script>
// @ is an alias to /src
import ApproveRequest from '@/components/ApproveRequest.vue'

export default {
  name: 'approve-request-view',
  components: {
    ApproveRequest
  }
}
</script>
