<template>
    <div>
      <EditBudget/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EditBudget from '@/components/EditBudget.vue'
  
  export default {
    name: 'edit-budget-view',
    components: {
      EditBudget
    }
  }
  </script>
  