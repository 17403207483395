<template>
    <div>
      <RequestHistory/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import RequestHistory from '@/components/RequestHistory.vue'
  
  export default {
    name: 'request-history-view',
    components: {
      RequestHistory
    }
  }
  </script>
  