<template>
    <div class="StrategyManagement">
      <StrategyManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import StrategyManagement from '@/components/StrategyManagement.vue'
  
  export default {
    name: 'strategy-management-view',
    components: {
      StrategyManagement
    }
  }
  </script>
  