<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> > Liquidation Edit Summary</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg  class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m10,23c0,.553-.447,1-1,1h-4c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h8c2.757,0,5,2.243,5,5v2c0,.553-.447,1-1,1s-1-.447-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h4c.553,0,1,.447,1,1ZM14,6c0-.553-.447-1-1-1H5c-.553,0-1,.447-1,1s.447,1,1,1h8c.553,0,1-.447,1-1Zm-4,5c0-.553-.447-1-1-1h-4c-.553,0-1,.447-1,1s.447,1,1,1h4c.553,0,1-.447,1-1Zm-5,4c-.553,0-1,.447-1,1s.447,1,1,1h2c.553,0,1-.447,1-1s-.447-1-1-1h-2Zm19,2c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-3.192-1.241l-2.223,2.134c-.144.141-.379.144-.522.002l-1.131-1.108c-.396-.388-1.028-.382-1.414.014-.387.395-.381,1.027.014,1.414l1.132,1.109c.46.449,1.062.674,1.663.674s1.201-.225,1.653-.671l2.213-2.124c.398-.383.411-1.016.029-1.414-.383-.4-1.017-.411-1.414-.029Z"/></svg>
                <p class="text-left text-size-md font-medium text-gray-600">Liquidation Summary</p>
              </div>
              <!-- The activity creation steps -->
              <div class="flex items-center col-span-full mx-5 mt-5">
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-teal-600"></div>
                <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 bg-emerald-600 ring-2 text-white py-0 px-1">3</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-teal-600"></div>
                <span class="cursor-pointer text-size-sm mr-4 ring-emerald-600 bg-emerald-600 ring-2 text-white py-0 px-1">4</span>
              </div>
              <div class="flex-1 mt-4">
                <button
                  @click="switchView(0)"
                  :class=" currentView == 0 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-700  text-white font-medium leading-tight focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out'">
                    Verify Request
                </button>
                <button
                  @click="switchView(1)"
                  :class=" currentView == 1 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-700  text-white font-medium leading-tight focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out'">
                    Verify Expense Report
                </button>
                <button
                  @click="switchView(2)"
                  :class=" currentView == 2 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-700  text-white font-medium leading-tight focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-700 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out'">
                    Verify Attached Files
                </button>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-4 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-6 sm:gap-y-6  ">
                <div v-if="currentView === 0" class="flex-1">
                  <span class="text-size-md font-medium text-gray-500 text-left"></span>
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer mb-4">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Item</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                      </tr>
                    </thead>
                      <tbody class="text-size-sm">
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Start Date</td>
                          <td class="py-3 px-2 border text-left">{{ editLiquidationRequest.name }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Advance Amount</td>
                          <td class="py-3 px-2 border text-left">{{ amount }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Advance Amount In Words</td>
                          <td class="py-3 px-2 border text-left">{{ numberToWords(amount) }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div  v-if="currentView === 1" class="flex-1 mb-4">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class="bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Project Name</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Currency</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Hotel</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">MI&E</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Other</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Notes</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in editExpenseReport" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.project }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.currency }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.hotel) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.mie) }}</td>
                        
                        <td v-if="data.other == 'Grand Total'" class="py-3 px-2 border font-bold text-left">{{ formatData(data.other) }}</td>
                        <td v-else class="py-3 px-2 border text-left">{{ formatData(data.other) }}</td>
                        
                        <td v-if="data.other == 'Grand Total'" class="py-3 px-2 border font-medium text-left">{{ formatData(data.notes)}}</td>
                        <td v-else class="py-3 px-2 border text-left">{{ data.notes}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div  v-if="currentView === 2"  class="flex-1 mb-4">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">#</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">File Name</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">File Size</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in editLiquidationUploadedFiles" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ (i + 1) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.realName }}</td>
                        <td class="py-3 px-2 border text-left">{{ fileSizeFormatter(data.size) }}</td>
                      </tr>
                    </tbody>
                </table>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-1">
                <button
                  @click="back()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight hover:scale-105 transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out">
                    Back
                </button>
                <button
                  @click="submit()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight hover:scale-105 transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out">
                    Resubmit
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loader modal -->
      <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
       <!-- the signature modal -->
       <SignatureModal
        @base64 = "(data) => {
          submitSignature(data)
        }"
        ref="signatureModal"
      />
      <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message="'Verify your liquidation details...'" />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import LoadingModal from './LoadingModal.vue'
  import MessageModal from './MessageModal.vue'
  import SignatureModal from './SignatureModal.vue'
  import { mapState } from 'vuex'
  import { ToWords } from 'to-words'
  import { ajax } from 'rxjs/ajax'
  import PopUp from './PopUp.vue'

  export default {
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token',
        'editLiquidationRequest',
        'editAdvanceAmount',
        'editLiquidationUploadedFiles',
        'editExpenseReport',
        'editLiquidationId'
      ])
    },
    components: {
      PopUp,
      Navbar,
      Sidebar,
      LoadingModal,
      MessageModal,
      SignatureModal
    },
    setup () {
      return {
      }
    },
    name: 'closure-summary',
    props: {
    },
    data: () => {
      return {
        amount: 0,
        currentView: 0,
        expandBody: false,
        signatureLoaded: false,
        signatureData: null,
        message: '',
        messageTitle: '',
        idOk: false,
        saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    mounted () {
      this.$refs.popupRef.show()
      // removing the submenu items selection
      this.amount = this.editAdvanceAmount
      this.$store.commit('mutateActiveListItemIndex', 12)
      this.$store.commit('mutateSelectedSubMenuItem', null)
      // check if the employee has a signature in the system
      this.getSignature()
    },
    methods: {
      // submit the base64 signature
      submitSignature (base64) {
        // open the loading modal
        this.$refs.loadingModal.openLoaderModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/signature/insert',
          method: 'POST',
          body: {
            employee_id: this.id,
            filename: base64
          },
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.message = 'Successfully submitted your signature...'
            this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to submit your signature, please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
            this.getSignature()
          }
        }

        observable.subscribe(observer)

      },
      // file size converter
      fileSizeFormatter (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) {
          return '0 Bytes'
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
      },
      // get the employee's signature
      getSignature () {
        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/signature/findByEmployeeId?id=' + this.id,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        const observer = {
          next: (response) => {
            const data = response.response
            this.signatureData = data
            const size = data.length
            this.signatureLoaded = true

            if (size == 0) {
              this.$refs.signatureModal.openSignatureModal()
            }

            // 
          },
          error: () => {},
          complete: () => {}
        }
        
        observable.subscribe(observer)
      },
      // amount in words
      numberToWords (amount) {
        const toWords = new ToWords({
          localeCode: 'en-GB',
          converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
              name: this.currency,
              plural: this.currency,
              symbol: this.currencyAcronym,
              fractionalUnit: {
                name: '',
                plural: '',
                symbol: ''
              }
            }
          }
        })

        if (amount === '' || amount === null || amount === undefined) {
          return toWords.convert(0)
        } else {
          return toWords.convert(amount)
        }
      },
      // format the table data
      formatData (data) {
        var formattedData
        // check if it is a number
        if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
          // format the number to a currency format
          var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
          formattedData = currencyFormat.format(String(data)).substring(4)
        } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
          // check if the data is a date
          // formating the dates supplied
          var textDate = new Date(data)
          var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
          formattedData = formattedTextDate
        } else {
          formattedData = data
        }
        return formattedData
      },
      // switches the activity summary being viewed
      switchView (index) {
        this.currentView = index
      },
      // moving to the next step
      back () {
        this.$router.push('edit-liquidation-attachment')
      },
      // submit () {
      //   // moving to the next step
      //   this.$router.push('timeline')
      // },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      },
      clearLiquidationVuex () {
        this.$store.commit('mutateEditLiquidationRequest', null)
        this.$store.commit('mutateEditAdvanceAmount', null)
        this.$store.commit('mutateEditExpenseReport', null)
        this.$store.commit('mutateEditLiquidationUploadedFiles', [])
      },
      submit () {
        if (this.signatureData.length > 0) {
          this.$refs.loadingModal.openLoaderModal()
          const observable = ajax({
            url: process.env.VUE_APP_BASE_URL + '/liquidation/update',
            method: 'PUT',
            body: {
              id: this.editLiquidationId,
              advance_amount: this.editAdvanceAmount,
              request_id: this.editLiquidationRequest.id,
              expense_report: JSON.stringify(this.editExpenseReport),
              attachments: JSON.stringify(this.editLiquidationUploadedFiles)
            },
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          })
    
          const observer = {
            next: () => {
              // clear the edit liquidation vuex
              this.clearLiquidationVuex()
    
              // moving to the activity timeline
              this.$router.push('liquidation-timeline')
            },
            error: () => {
              // close the loader modal
              this.$refs.loadingModal.closeLoaderModal()
              // showing the dialog for the error
              this.isOk = false
              this.messageTitle = 'Error Message'
              this.message = 'Failed to submit your liquidation, please try again later...'
              this.$refs.messageModal.openMessageModal()
            },
            complete: () => {
              this.$refs.loadingModal.closeLoaderModal()
            }
          }
    
          observable.subscribe(observer)
        } else {
          this.$refs.signatureModal.openSignatureModal()
        }
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  