<template>
    <div>
      <ViewRequestHistory/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ViewRequestHistory from '@/components/ViewRequestHistory.vue'
  
  export default {
    name: 'view-request-history-view',
    components: {
      ViewRequestHistory
    }
  }
  </script>
  