<template>
  <div>
    <DownloadLiquidation/>
  </div>
</template>

<script>
// @ is an alias to /src
import DownloadLiquidation from '@/components/DownloadLiquidation.vue'

export default {
  name: 'admin-view-liquidation',
  components: {
    DownloadLiquidation
  }
}
</script>
