<template>
    <div class="OtherParticipants">
      <OtherParticipants/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import OtherParticipants from '@/components/OtherParticipants.vue'
  
  export default {
    name: 'admin-OtherParticipants-view',
    components: {
      OtherParticipants
    }
  }
  </script>
  