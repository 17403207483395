<template>
    <div class="StakeholderManagement">
      <StakeholderManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import StakeholderManagement from '@/components/StakeholderManagement.vue'
  
  export default {
    name: 'admin-StakeholderManagement-view',
    components: {
      StakeholderManagement
    }
  }
  </script>
  