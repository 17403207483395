<template>
    <div>
      <EmployeeLiquidation/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EmployeeLiquidation from '@/components/EmployeeLiquidation.vue'
  
  export default {
    name: 'liquidation-approval-history-view',
    components: {
      EmployeeLiquidation
    }
  }
  </script>
  