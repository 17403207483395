  import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    token: null,
    id: null,
    email: null,
    firstname: null,
    surname: null,
    position: null,
    activeListItemIndex: null,
    selectedSubMenuItem: null,
    memo: null,
    schedule: null,
    egpafBudget: null,
    egpafPayments: null,
    mohPayments: null,
    mohBudget: null,
    unitBudget: null,
    editMemo: null,
    editSchedule: null,
    editEgpafBudget: null,
    editEgpafPayments: null,
    editMohPayments: null,
    editMohBudget: null,
    editUnitBudget: null,
    vuexEditUploadedFiles: null,
    report: null,
    expenseReport: null,
    closureActivity: null,
    loginId: null,
    officeId: null,
    district: null,
    advanceAmount: null,
    editRequestId: null,
    liquidationRequest: null,
    vuexUploadedFiles: [],
    liquidationUploadedFiles: [],
    editLiquidationRequest: null,
    editAdvanceAmount: null,
    editExpenseReport: null,
    editLiquidationUploadedFiles: [],
    editLiquidationId: null,
    sidebarClosed: null,
    pr: null,
    editPr: null
  },
  mutations: {
    mutateToken (state, token) {
      state.token = token
    },
    mutateId (state, id) {
      state.id = id
    },
    mutateEmail (state, email) {
      state.email = email
    },
    mutateFirstname (state, firstname) {
      state.firstname = firstname
    },
    mutateSurname (state, surname) {
      state.surname = surname
    },
    mutatePosition (state, position) {
      state.position = position
    },
    mutateActiveListItemIndex (state, activeListItemIndex) {
      state.activeListItemIndex = activeListItemIndex
    },
    mutateSelectedSubMenuItem (state, selectedSubMenuItem) {
      state.selectedSubMenuItem = selectedSubMenuItem
    },
    mutateMemo (state, memo) {
      state.memo = memo
    },
    mutateSchedule (state, schedule) {
      state.schedule = schedule
    },
    mutateMoHPayments (state, mohPayments) {
      state.mohPayments = mohPayments
    },
    mutateEgpafPayments (state, egpafPayments) {
      state.egpafPayments = egpafPayments
    },
    mutateEgpafBudget (state, egpafBudget) {
      state.egpafBudget = egpafBudget
    },
    mutateUnitBudget (state, unitBudget) {
      state.unitBudget = unitBudget
    },
    mutateMoHBudget (state, mohBudget) {
      state.mohBudget = mohBudget
    },
    mutateClosureActivity (state, closureActivity) {
      state.closureActivity = closureActivity
    },
    mutateReport (state, report) {
      state.report = report
    },
    mutateExpenseReport (state, expenseReport) {
      state.expenseReport = expenseReport
    },
    mutateAdvanceAmount (state, advanceAmount) {
      state.advanceAmount = advanceAmount
    },
    mutateLoginId (state, loginId) {
      state.loginId = loginId
    },
    mutateUploadedFiles (state, vuexUploadedFiles) {
      state.vuexUploadedFiles = vuexUploadedFiles
    },
    mutateOfficeId (state, officeId) {
      state.officeId = officeId
    },
    mutateDistrict (state, district) {
      state.district = district
    },
    mutateLiquidationRequest (state, liquidationRequest) {
      state.liquidationRequest = liquidationRequest
    },
    mutateLiquidationUploadedFiles (state, liquidationUploadedFiles) {
      state.liquidationUploadedFiles = liquidationUploadedFiles
    },
    mutateEditMemo (state, editMemo) {
      state.editMemo = editMemo
    },
    mutateEditSchedule (state, editSchedule) {
      state.editSchedule = editSchedule
    },
    mutateEditMoHPayments (state, editMohPayments) {
      state.editMohPayments = editMohPayments
    },
    mutateEditEgpafPayments (state, editEgpafPayments) {
      state.editEgpafPayments = editEgpafPayments
    },
    mutateEditEgpafBudget (state, editEgpafBudget) {
      state.editEgpafBudget = editEgpafBudget
    },
    mutateEditUnitBudget (state, editUnitBudget) {
      state.editUnitBudget = editUnitBudget
    },
    mutateEditMoHBudget (state, editMohBudget) {
      state.editMohBudget = editMohBudget
    },
    mutateEditUploadedFiles (state, vuexEditUploadedFiles) {
      state.vuexEditUploadedFiles = vuexEditUploadedFiles
    },
    mutateEditRequestId (state, editRequestId) {
      state.editRequestId = editRequestId
    },
    mutateEditLiquidationRequest (state, editLiquidationRequest) {
      state.editLiquidationRequest = editLiquidationRequest
    },
    mutateEditAdvanceAmount (state, editAdvanceAmount) {
      state.editAdvanceAmount = editAdvanceAmount
    },
    mutateEditExpenseReport (state, editExpenseReport) {
      state.editExpenseReport = editExpenseReport
    },
    mutateEditLiquidationUploadedFiles (state, editLiquidationUploadedFiles) {
      state.editLiquidationUploadedFiles = editLiquidationUploadedFiles
    },
    mutateEditLiquidationId (state, editLiquidationId) {
      state.editLiquidationId = editLiquidationId
    },
    mutateSidebarClosed (state, sidebarClosed) {
      state.sidebarClosed = sidebarClosed
    },
    mutatePr (state, pr) {
      state.pr = pr
    },
    mutateEditPr (state, editPr) {
      state.editPr = editPr
    }
  },
  actions: {
  },
  modules: {
  }
})
