<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> > Edit Liquidation</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,18.77v-4.27c0-2.48-2.02-4.5-4.5-4.5h-7c-2.48,0-4.5,2.02-4.5,4.5v4.27c-.61,.55-1,1.35-1,2.23,0,1.65,1.35,3,3,3h12c1.65,0,3-1.35,3-3,0-.89-.39-1.68-1-2.23Zm-14-4.27c0-1.38,1.12-2.5,2.5-2.5h7c1.38,0,2.5,1.12,2.5,2.5v3.5h-2.59c-.53,0-1.04,.21-1.41,.59l-.41,.41h-3.17l-.41-.41c-.37-.37-.89-.59-1.41-.59h-2.59v-3.5Zm12,7.5H9c-.55,0-1-.45-1-1s.45-1,1-1h2.59l.41,.41c.37,.37,.89,.59,1.41,.59h3.17c.53,0,1.04-.21,1.41-.59l.41-.41h2.59c.55,0,1,.45,1,1s-.45,1-1,1ZM4,16c-1.1,0-2-.9-2-2v-5.87c0-.93,.42-1.79,1.15-2.36L7.15,2.64c1.09-.85,2.61-.85,3.7,0l4,3.13c.5,.39,.85,.91,1.03,1.51,.16,.53,.71,.84,1.24,.68,.53-.16,.83-.71,.68-1.24-.29-1.01-.89-1.88-1.71-2.53l-.08-.06V1c0-.55-.45-1-1-1s-1,.45-1,1v1.56l-1.92-1.5C10.27-.36,7.73-.36,5.92,1.06L1.92,4.19c-1.22,.95-1.92,2.39-1.92,3.94v5.87c0,2.21,1.79,4,4,4,.55,0,1-.45,1-1s-.45-1-1-1ZM15.99,4.12h0s0,0,0,0Z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="m24,5v1c0,2.757-2.243,5-5,5h-4.5c-.828,0-1.5-.671-1.5-1.5s.672-1.5,1.5-1.5h4.5c1.103,0,2-.897,2-2v-1c0-1.103-.897-2-2-2H5c-1.103,0-2,.897-2,2v1c0,.989.736,1.84,1.713,1.979.819.117,1.39.877,1.272,1.697-.118.82-.885,1.386-1.698,1.272-2.444-.35-4.287-2.478-4.287-4.949v-1C0,2.243,2.243,0,5,0h14c2.757,0,5,2.243,5,5Zm-6.92,11.708l-6.08-1.824v-5.291c0-.704-.447-1.368-1.129-1.543-1.001-.256-1.884.492-1.884,1.449v10.269l-1.64-1.296c-.866-.722-2.153-.604-2.874.261-.722.866-.605,2.153.261,2.874l1.68,1.483c.663.585,1.516.908,2.4.908h.762c1.338,0,2.423-1.085,2.423-2.423v-3.561l5.219,1.566c1.065.319,1.781,1.281,1.781,2.394v.524c0,.829.672,1.5,1.5,1.5s1.5-.671,1.5-1.5v-.524c0-2.448-1.575-4.565-3.92-5.268Z"/>
                </svg>
                <p class="text-left text-size-md font-medium text-gray-600">selectedRequest Closure Selection</p>
              </div>
              <!-- The selectedRequest creation steps -->
              <div class="flex items-center col-span-full mx-5 mt-5">
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm ring-emerald-600 ring-2 text-gray-500 py-0 px-1">2</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              </div>
              <!-- select the request -->
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-6 sm:gap-y-6  ">
                
                <div>
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Select the Request <span class="font-bold text-red-500">*</span></label>
                  <div v-if="showselectedRequestSelection" class="space-x-0.5">
                    <CustomDropDown
                      @selectedItems="(data) => { 
                        selectedRequest = data
                        getRequest(selectedRequest.id)
                      }"
                      :elementId="'requestSearchId'"
                      :url="'/request/search'"
                      :persistedSelection="selectedRequest"
                      :multipleSelection="false"
                      :params="`&employeeId=${id}`"
                      :autosearch="true"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>

                <!-- the amount -->
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Advance Total Amount <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="amount"
                      placeholder="Expense Amount"
                      required
                      type="text"
                      class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                  </div>
                </div>

                <!-- the amount in words -->
                <div class="col-span-full sm:col-span-1 ">
                  <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Advance Amount In Words <span class="font-bold text-red-500">*</span></label>
                  <div class="flex ">
                    <input
                      v-model="amountInWords"
                      placeholder="Expense Amount In Words"
                      required
                      type="text"
                      disabled
                      class="bg-gray-100 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-1">
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight hover:scale-105 transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
      <!-- add budget modal -->
      <TransitionRoot appear :show="isAddBudgetOpen" @close="closeAddBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-3xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add a Budget
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Date -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="date"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Decription"
                          requiemerald
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- No of employees -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">No Of Employees <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="noEmployees"
                          requiemerald
                          placeholder="Number of Employee"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Amount -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Amount <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="amount"
                          requiemerald
                          placeholder="Amount"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="addBudgetData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- the pop up modal -->
      <PopUp 
        ref="popupRef"
        :message="saveMessage" />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import MessageModal from './MessageModal.vue'
  import CustomDropDown from './CustomDropDown.vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ref } from 'vue'
  import { interval } from 'rxjs'
  import { ajax } from 'rxjs/ajax'
  import { ToWords } from 'to-words'
  import PopUp from './PopUp.vue'
  
  export default {
    watch: {
      amount: function (newValue) {
        if (newValue) {
          // get the word value of the amount
          this.numberToWords()
        }
      }
    },
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token',
        'editExpenseReport',
        'editAdvanceAmount',
        'editLiquidationRequest'
      ])
    },
    components: {
      PopUp,
      Navbar,
      Sidebar,
      MessageModal,
      CustomDropDown,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
    },
    setup () {
      const isAddBudgetOpen = ref(false)
      const isEditBudgetOpen = ref(false)
      return {
        isAddBudgetOpen,
        isEditBudgetOpen,
        closeAddBudgetModal () {
          isAddBudgetOpen.value = false
        },
        openAddBudgetModal () {
          isAddBudgetOpen.value = true
        },
        closeEditBudgetModal () {
          isEditBudgetOpen.value = false
        },
        openEditBudgetModal () {
          isEditBudgetOpen.value = true
        }
      }
    },
    name: 'edit-liquidation-closure',
    props: {
    },
    data: () => {
      return {
        isOk: true,
        messageTitle: '',
        message: '',
        amount: 0,
        currency: '',
        amountInWords: '',
        currencyAcronym: '',
        selectedRequest: [],
        expandBody: false,
        selectedEditIndex: -1,
        saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
        BASE_URL: process.env.VUE_APP_BASE_URL,
        showselectedRequestSelection: false
      }
    },
    mounted () {
      // removing the submenu items selection
      this.$store.commit('mutateActiveListItemIndex', 12)
      this.$store.commit('mutateSelectedSubMenuItem', null)
      // load the stoemerald closure selectedRequest
      this.loadliquidationRequest()
      // automatically save the entered data
      this.autosave()
    },
    methods: {
      // amount in words
      numberToWords () {
        const toWords = new ToWords({
          localeCode: 'en-GB',
          converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
              name: this.currency,
              plural: this.currency,
              symbol: this.currencyAcronym,
              fractionalUnit: {
                name: '',
                plural: '',
                symbol: ''
              }
            }
          }
        })

        if (this.amount === '' || this.amount === null || this.amount === undefined) {
          this.amountInWords = toWords.convert(0)
        } else {
          this.amountInWords = toWords.convert(this.amount)
        }
      },
      // get the request
      getRequest (id) {
        if (id !== undefined) {
          const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/findById?id=' + id,
          headers: {
            Authorization: `Bearer ${this.token}`
          }
          })
          const observer = {
            next: (response) => {
              const requestData = response.response
              const memo = requestData[0].memo
              const budget = requestData[0].budget
              
              // the currency
              this.currency = memo.currency.name
              this.currencyAcronym = memo.currency.allData.acronym

              // the budget
              const egpafBudget = budget[0].data
              const mohBudget = budget[1].data
              const unitBudget = budget[2].data
              
              
              // get the budget totals
              const egpafBudgetSize = egpafBudget.length
              var egpafBudgetTotal = 0

              if (egpafBudgetSize > 0) {
                egpafBudgetTotal = egpafBudget[(egpafBudgetSize - 1)].total
              }

              const mohBudgetSize = mohBudget.length
              var mohBudgetTotal = 0
              
              if (mohBudgetSize > 0) {
                mohBudgetTotal = mohBudget[(mohBudgetSize - 1)].total
              }

              const unitBudgetSize = unitBudget.length
              var unitBudgetTotal = 0

              if (unitBudgetSize > 0) {
                unitBudgetTotal = unitBudget[(unitBudgetSize - 1)].total
              }

              const budgetTotals = parseFloat(egpafBudgetTotal) + parseFloat(mohBudgetTotal) + parseFloat(unitBudgetTotal)
              this.amount = budgetTotals
            },
            error: () => {},
            complete: () => {}
          }
          
          observable.subscribe(observer)
        }
      },
      // automatically save the data
      autosave () {
        this.$refs.popupRef.show()
        const observable = interval(1000)
        const observer = {
          next: () => {
            this.$store.commit('mutateEditLiquidationRequest', this.selectedRequest)
            this.$store.commit('mutateEditAdvanceAmount', this.amount)
          },
          error: () => {

          },
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      // load the stoemerald closure selectedRequest
      loadliquidationRequest () {
        if (this.editLiquidationRequest != null) {
          this.selectedRequest = this.editLiquidationRequest
          this.amount = this.editAdvanceAmount
          this.getRequest(this.selectedRequest.id)
        }
        this.showselectedRequestSelection = true
      },
      next () {
        // if (this.selectedRequest.id !== null && this.selectedRequest.id !== undefined) {
        //   // moving to the next step
        //   this.$router.push('expenseReport')
        //   // store the data
        //   this.$store.commit('mutateliquidationRequest', this.selectedRequest)
        // } else {
        //   this.isOk = false
        //   this.messageTitle = 'Error Message'
        //   this.message = 'Select an selectedRequest to move to the next step...'
        //   this.$refs.messageModal.openMessageModal()
        // }
        this.$router.push('edit-expense-report')
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  