<template>
  <div>
    <ApprovalHistory/>
  </div>
</template>
  
  <script>
  // @ is an alias to /src
  import ApprovalHistory from '@/components/ApprovalHistory.vue'
  
  export default {
    name: 'penidng-approval-view',
    components: {
      ApprovalHistory
    }
  }
  </script>
  