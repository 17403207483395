<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> > Add Attachments</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z"/><circle cx="12" cy="15" r="1.5"/><circle cx="7" cy="15" r="1.5"/><circle cx="17" cy="15" r="1.5"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M6.983,24.007A7,7,0,0,1,2.02,12.054l10.6-10.585a5.008,5.008,0,0,1,7.091,7.075L9.11,19.128a3.083,3.083,0,0,1-4.254,0,3,3,0,0,1,0-4.245L14.75,5.007l1.418,1.415L6.274,16.3a1,1,0,0,0,0,1.414,1.027,1.027,0,0,0,1.418,0L18.3,7.129a3.005,3.005,0,1,0-4.255-4.245L3.438,13.468a5.008,5.008,0,0,0,7.09,7.076l12.021-12,1.418,1.415-12.021,12A7,7,0,0,1,6.983,24.007Z"/></g></svg>
                <p class="text-left text-size-md font-medium text-gray-600">Add Attachments</p>
              </div>
              <!-- The activity creation steps -->
              <div class="flex items-center col-span-full mx-5 mt-5">
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">3</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">4</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer animate-pulse mx-0.5 text-size-sm ring-emerald-600 bg-emerald-600 ring-2 text-white py-0 px-1">5</span>
                <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
                <span class="cursor-pointer text-size-sm mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">6</span>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 sm:gap-y-1.5">
                <div class="space-x-0.5 mb-2">
                  <div @click="clearSchedule()" class="shadow cursor-pointer inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    <form
                      :action="`${BASE_URL}/request/uploadRequestFile`"
                      id="form-2">
                      Click Here To Select & Upload a Files
                    </form>
                  </div>
                </div>
                <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class="bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">#</td>
                        <td class="py-3 px-2 text-left text-white font-bold">File Name</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">File Size</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody v-if="showAttachments">
                      <tr v-for="(data, i) in uploadedFiles" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ (i + 1) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.realName) }}</td>
                        <td class="py-3 px-2 border text-left">{{ fileSizeFormatter(data.size) }}</td>
                        <td class="py-3 px-2 border text-center flex">
                          <div class="flex mx-auto space-x-4">
                            <svg @click="deleteItem(i)" class="h-5 w-5 text-red-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-0.5">
                <button
                  @click="back()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Back
                </button>
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
      <!-- add schedule modal -->
      <TransitionRoot appear :show="isAddScheduleOpen" @close="closeAddScheduleModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-3xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add a Schedule
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Description -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          requiemerald
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Place -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Place/Venue <span class="font-bold text-red-500">*</span></label>
                      <div class=" w-full">
                        <CustomDropDown
                          @selectedItems="(data) => { place = data}"
                          :elementId="'place'"
                          :url="'/venue/search'"
                          :persistedSelection="place"
                          :multipleSelection="false"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                    <!-- Start date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="startDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- End date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="endDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddScheduleModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="addScheduleData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- edit schedule modal -->
      <TransitionRoot appear :show="isEditScheduleOpen" @close="closeEditScheduleModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-3xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit a Schedule
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Description -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          requiemerald
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Place -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Place <span class="font-bold text-emerald-500">*</span></label>
                      <div class=" w-full">
                        <CustomDropDown
                          @selectedItems="(data) => { place = data }"
                          :elementId="'place'"
                          :url="'/location/findByDistrictOrCountry'"
                          :persistedSelection="place"
                          :multipleSelection="false"
                          :display="{
                            id: 'id',
                            name: ['district', 'country']
                          }"
                        />
                      </div>
                    </div>
                    <!-- Start date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="startDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- End date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-emerald-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="endDate"
                          requiemerald
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeEditScheduleModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editScheduleData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- Loader modal -->
      <LoadingModal
        ref="loadingModal"
      />
      <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message="saveMessage" />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import MessageModal from './MessageModal.vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ref } from 'vue'
  import Dropzone from 'dropzone'
  import CustomDropDown from './CustomDropDown.vue'
  import LoadingModal from './LoadingModal.vue'
  import PopUp from './PopUp.vue'
  
  export default {
    computed: {
      ...mapState([
        'firstname',
        'surname',
        'token',
        'schedule',
        'vuexUploadedFiles'
      ])
    },
    components: {
      PopUp,
      Navbar,
      Sidebar,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      MessageModal,
      CustomDropDown,
      LoadingModal
    },
    setup () {
      const isAddScheduleOpen = ref(false)
      const isEditScheduleOpen = ref(false)
      return {
        isAddScheduleOpen,
        isEditScheduleOpen,
        closeAddScheduleModal () {
          isAddScheduleOpen.value = false
        },
        openAddScheduleModal () {
          isAddScheduleOpen.value = true
        },
        closeEditScheduleModal () {
          isEditScheduleOpen.value = false
        },
        openEditScheduleModal () {
          isEditScheduleOpen.value = true
        }
      }
    },
    name: 'create-attachment',
    props: {
    },
    data: () => {
      return {
        isOk: true,
        messageTitle: '',
        message: '',
        scheduleData: [],
        startDate: '',
        endDate: '',
        description: '',
        place: [],
        BASE_URL: process.env.VUE_APP_BASE_URL,
        saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
        uploadedFiles: [],
        showAttachments: false,
        expandBody: false,
        selectedEditIndex: -1,
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    mounted () {
      this.$refs.popupRef.show()
      // the second dropzone
      const myDropzone2 = new Dropzone('#form-2', {
        headers: { Authorization: `Bearer ${this.token}` },
        disablePreviews: true
      })
      // callback for the file added
      myDropzone2.on('addedfile', () => {
        this.$refs.loadingModal.openLoaderModal()
      })
      // the second drop zone
      myDropzone2.on('complete', (file) => {
        try {
        const fileDetails = JSON.parse(file.xhr.response)
        const realFilename = fileDetails.realName
        const renamedFilename = fileDetails.renamedFilename

        // get the file details
        const fileData = { realName: realFilename, name: renamedFilename, size: file.size }
        // push the file into the uploaded files []

        this.uploadedFiles.push(fileData)
        // save the uploaded files data in vuex
        this.$store.commit('mutateUploadedFiles', this.uploadedFiles)
        myDropzone2.removeFile(file)
        this.$refs.loadingModal.closeLoaderModal()
        } catch (e) {
          console.log(e)
        }
      })
      // load the vuex data
      this.uploadedFiles = this.vuexUploadedFiles
      this.showAttachments = true
    },
    methods: {
      // file size converter
      fileSizeFormatter (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) {
          return '0 Bytes'
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
      },
      // edit a schedule row
      editScheduleData () {
        // check if the details are empty
        if (this.description !== '' && this.place !== undefined && this.startDate !== '' && this.endDate !== '') {
          // hide the schedule
          this.showAttachments = false
          // adding the data
          const rowData = {
            startDate: this.startDate,
            endDate: this.endDate,
            description: this.description,
            place: this.place
          }
          // edit it in the schedule
          this.scheduleData[this.selectedEditIndex] = rowData
          // show the schedule
          this.showAttachments = true
          //  close the add a schedule modal
          this.closeEditScheduleModal()
        }
      },
      // deletes an item at an index
      deleteItem (index) {
        this.showAttachments = false
        this.uploadedFiles.splice(index, 1)
        this.showAttachments = true
      },
      // loads the edit schedule modal
      loadEditScheduleModal (index) {
        this.selectedEditIndex = index
        const rowData = this.scheduleData[index]
        this.startDate = rowData.startDate
        this.endDate = rowData.endDate
        this.description = rowData.description
        this.place = rowData.place
        // open the edit schedule moda
        this.openEditScheduleModal()
      },
      // loads the stoemerald schedule
      loadSchedule () {
        if (this.schedule !== null) {
          this.showAttachments = false
          this.scheduleData = this.schedule
          this.showAttachments = true
        }
      },
      // format the table data
      formatData (data) {
        var formattedData
        // check if it is a number
        if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
          // format the number to a currency format
          var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
          formattedData = currencyFormat.format(String(data)).substring(4)
        } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
          // check if the data is a date
          // formating the dates supplied
          var textDate = new Date(data)
          var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
          formattedData = formattedTextDate
        } else {
          formattedData = data
        }
        return formattedData
      },
      // add a schedule data
      addScheduleData () {
        // check if the details are empty
        if (this.description !== '' && this.place !== undefined && this.startDate !== '' && this.endDate !== '') {
          // hide the schedule
          this.showAttachments = false
          // adding the data
          const rowData = {
            startDate: this.startDate,
            endDate: this.endDate,
            description: this.description,
            place: this.place
          }
          // add it in the schedule
          this.scheduleData.push(rowData)
          // show the schedule
          this.showAttachments = true
          //  close the add a schedule modal
          this.closeAddScheduleModal()
        }
      },
      // clear the schedule
      clearSchedule () {
        this.scheduleData = []
      },
      back () {
        // moving to the next step
        this.$router.push('create-schedule')
      },
      next () {
        // if (this.scheduleData.length !== 0) {
        //   // moving to the next step
        //   this.$router.push('createBudget')
        //   // store the data
        //   this.$store.commit('mutateSchedule', this.scheduleData)
        // } else {
        //   this.isOk = false
        //   this.messageTitle = 'Error Message'
        //   this.message = 'Fill at least one row in the schedule to move to the next step...'
        //   this.$refs.messageModal.openMessageModal()
        // }
        this.$router.push('request-summary')
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  