<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']" >
          <!-- the top route and username grid -->`
          <div class="justify-between flex text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Home</span> > Edit Expense Report</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg  class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m24,12c0,.553-.448,1-1,1h-10v10c0,.553-.448,1-1,1s-1-.447-1-1v-10H1c-.552,0-1-.447-1-1s.448-1,1-1h10V1c0-.553.448-1,1-1s1,.447,1,1v10h10c.552,0,1,.447,1,1Zm-1,4h-6c-.552,0-1,.447-1,1s.448,1,1,1h6c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-6c-.552,0-1,.447-1,1s.448,1,1,1h6c.552,0,1-.447,1-1s-.448-1-1-1ZM1,6h6c.552,0,1-.447,1-1s-.448-1-1-1H1c-.552,0-1,.447-1,1s.448,1,1,1Zm16,0h2v2c0,.553.448,1,1,1s1-.447,1-1v-2h2c.552,0,1-.447,1-1s-.448-1-1-1h-2v-2c0-.553-.448-1-1-1s-1,.447-1,1v2h-2c-.552,0-1,.447-1,1s.448,1,1,1Zm-9.293,10.293c-.391-.391-1.023-.391-1.414,0l-1.793,1.793-1.793-1.793c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l1.793,1.793-1.793,1.793c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l1.793-1.793,1.793,1.793c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414l-1.793-1.793,1.793-1.793c.391-.391.391-1.023,0-1.414Z"/></svg>
                <p class="text-left text-size-md font-medium text-gray-600">Expense Report</p>
              </div>
              <!-- The activity creation steps -->
              <div class="flex items-center col-span-full mx-5 mt-5">
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-400"></div>
                <span class="ml-4 mr-0.5 cursor-pointer text-size-sm bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-400"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
                <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-400"></div>
                <span class="mx-0.5 cursor-pointer text-size-sm ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 sm:gap-y-2">
                <div class="">
                  <button @click="resetExpenseReport()" class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Reset</button>
                  <button @click="openAddBudgetModal()" class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add Details</button>
                </div>
                <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Project Name(s)</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Currency</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Hotel</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">MI&E</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Other</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Notes</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody v-if="showExpenseReport">
                      <tr v-for="(data, i) in expenseReportData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.project) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.currency) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.hotel) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.mie) }}</td>
                        
                        <td v-if="data.other == 'Grand Total'" class="py-3 px-2 border text-left font-bold">{{ formatData(data.other) }}</td>
                        <td v-else class="py-3 px-2 border text-left">{{ formatData(data.other) }}</td>
                        
                        <td v-if="data.other == 'Grand Total'" class="py-3 px-2 border text-left font-bold">{{ formatData(data.notes) }}</td>
                        <td v-else class="py-3 px-2 border text-left">{{ formatData(data.notes) }}</td>
                        
                        <td class="py-3 px-2 border text-center flex">
                          <div v-if="i !== expenseReportData.length - 1" class="flex mx-auto space-x-4">
                            <svg @click="deleteItem(i)" class="h-5 w-5 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <!-- <svg @click="loadEditBudgetModal(i)" class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg> -->
                            <svg @click="loadEditBudgetModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                          <div v-else class="flex mx-auto space-x-4">
                            <svg class="h-5 w-5 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <svg class="h-5 w-5 text-white fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-1">
                <button
                  @click="back()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight hover:scale-105 transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Back
                </button>
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight hover:scale-105 transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
      <!-- add expense report details modal -->
      <TransitionRoot appear :show="isAddBudgetOpen" @close="closeAddBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add Expense Report Details
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Date -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="date"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Hotel -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Hotel <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="hotel"
                          required
                          placeholder="Hotel"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- MI&E -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">MI&E <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mie"
                          required
                          placeholder="MI&E"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Other -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Other <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="other"
                          required
                          placeholder="Other"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Notes -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Notes <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="notes"
                          required
                          placeholder="Notes"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="addexpenseReportData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- edit the expense report details modal -->
      <TransitionRoot appear :show="isEditBudgetOpen" @close="closeEditBudgetModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit Expense Report Details
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                     <!-- Date -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="date"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Description <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="description"
                          placeholder="Description"
                          required
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Hotel -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Hotel <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="hotel"
                          required
                          placeholder="Hotel"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- MI&E -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">MI&E <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="mie"
                          required
                          placeholder="MI&E"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Other -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Other <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="other"
                          required
                          placeholder="Other"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Notes -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Notes <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="notes"
                          required
                          placeholder="Notes"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddBudgetModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editexpenseReportData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-500 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- the pop up modal -->
      <PopUp 
        ref="popupRef"
        :message="saveMessage" />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import MessageModal from './MessageModal.vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ref } from 'vue'
  import { ajax } from 'rxjs/ajax'
  import { interval } from 'rxjs'
  import PopUp from './PopUp.vue'

  export default {
    computed: {
      ...mapState([
        'firstname',
        'surname',
        'token',
        'editExpenseReport',
        'editLiquidationRequest'
      ])
    },
    components: {
      PopUp,
      Navbar,
      Sidebar,
      MessageModal,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
    },
    setup () {
      const isAddBudgetOpen = ref(false)
      const isEditBudgetOpen = ref(false)
      return {
        isAddBudgetOpen,
        isEditBudgetOpen,
        closeAddBudgetModal () {
          isAddBudgetOpen.value = false
        },
        openAddBudgetModal () {
          isAddBudgetOpen.value = true
        },
        closeEditBudgetModal () {
          isEditBudgetOpen.value = false
        },
        openEditBudgetModal () {
          isEditBudgetOpen.value = true
        }
      }
    },
    name: 'edit-expense-report',
    props: {
    },
    data: () => {
      return {
        isOk: true,
        messageTitle: '',
        message: '',
        date: '',
        description: '',
        hotel: 0,
        mie: 0,
        other: 0,
        notes: '',
        currency: '',
        currencyAcronym: '',
        budgetSource: '',
        expenseReportData: [],
        expandBody: false,
        showExpenseReport: false,
        selectedEditIndex: -1,
        BASE_URL: process.env.VUE_APP_BASE_URL,
        saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    mounted () {
      // removing the submenu items selection
      this.$store.commit('mutateActiveListItemIndex', 12)
      this.$store.commit('mutateSelectedSubMenuItem', null)
      
      // load the stored expense report
      this.loadExpenseReport()

      // auto save the data
      this.autosave()
    },
    methods: {
      // autosave the expense report data
      autosave () {
        this.$refs.popupRef.show()
        const observable = interval(1000)
        const observer = {
          next: () => {
            this.$store.commit('mutateEditExpenseReport', this.expenseReportData)
          },
          error: () => {}
        }
        observable.subscribe(observer)
      },
      // get the request
      getRequest (loadData) {
        this.showExpenseReport = false
        const id = this.editLiquidationRequest.id
        const observable = ajax({
        method: 'GET',
        url: this.BASE_URL + '/request/findById?id=' + id,
        headers: {
          Authorization: `Bearer ${this.token}`
        }
        })
        const observer = {
          next: (response) => {
            // the response data
            const requestData = response.response
            const memo = requestData[0].memo
            const budget = requestData[0].budget
            
            // the expense report []
            const expenseReport = []

            // the currency
            this.currency = memo.currency.name
            this.currencyAcronym = memo.currency.allData.acronym

            // the budget source
            this.budgetSource = memo.budgetSource.map((data) => data.name).join(',')

            if (loadData) {
              // the budget
              const egpafBudget = budget[0].data
              const mohBudget = budget[1].data
              const unitBudget = budget[2].data
              
              
              // get the budget size
              var egpafBudgetTotal = 0
              const egpafBudgetSize = egpafBudget.length

              if (egpafBudgetSize > 0) {
                egpafBudgetTotal = egpafBudget[(egpafBudgetSize - 1)].total

                // loop through the budget and get the the expense report details
                for (let i = 0; i < (egpafBudgetSize - 1); i++) {
                  const rowData = egpafBudget[i]
                  const date = rowData.date
                  const description = rowData.description
                  const total = rowData.total

                  // push the data into the expense report []
                  const egpafBudgetExpenseReportData = {
                    date: date,
                    description: description,
                    project: this.budgetSource,
                    currency: this.currencyAcronym,
                    hotel: 0,
                    mie: total,
                    other: 0,
                    notes: ''
                  }

                  expenseReport.push(egpafBudgetExpenseReportData)
                }
              }

              // the moh budget size
              const mohBudgetSize = mohBudget.length
              var mohBudgetTotal = 0
            
              if (mohBudgetSize > 0) {
                mohBudgetTotal = mohBudget[(mohBudgetSize - 1)].total

                // loop through the budget and get the the expense report details
                for (let i = 0; i < (mohBudgetSize - 1); i++) {
                  const rowData = mohBudget[i]
                  const date = rowData.date
                  const description = rowData.description
                  const total = rowData.total

                  // push the data into the expense report []
                  const mohBudgetExpenseReportData = {
                    date: date,
                    description: description,
                    project: this.budgetSource,
                    currency: this.currencyAcronym,
                    hotel: 0,
                    mie: total,
                    other: 0,
                    notes: ''
                  }

                  expenseReport.push(mohBudgetExpenseReportData)
                }
              }

              const unitBudgetSize = unitBudget.length
              var unitBudgetTotal = 0

              if (unitBudgetSize > 0) {
                unitBudgetTotal = unitBudget[(unitBudgetSize - 1)].total
                // loop through the budget and get the the expense report details
                for (let i = 0; i < (mohBudgetSize - 1); i++) {
                  const rowData = mohBudget[i]
                  const date = rowData.date
                  const description = rowData.description
                  const total = rowData.total

                  // push the data into the expense report []
                  const unitBudgetExpenseReportData = {
                    date: date,
                    description: description,
                    project: this.budgetSource,
                    currency: this.currencyAcronym,
                    hotel: 0,
                    mie: 0,
                    other: total,
                    notes: ''
                  }

                  expenseReport.push(unitBudgetExpenseReportData)
                }
              }

              const budgetTotals = parseFloat(egpafBudgetTotal) + parseFloat(mohBudgetTotal) + parseFloat(unitBudgetTotal)


              // push the data into the expense report []
              const totalsData = {
                date: '',
                description: '',
                project: '',
                currency: '',
                hotel: '',
                mie: '',
                other: 'Grand Total',
                notes: budgetTotals
              }

              expenseReport.push(totalsData)

              this.expenseReportData = expenseReport
            }

            this.showExpenseReport = true
          },
          error: () => {},
          complete: () => {}
        }
        
        observable.subscribe(observer)
      },
      // load data for the selected index
      loadEditBudgetModal (index) {
        // assign the selected index
        this.selectedEditIndex = index
        // load the data
        const data = this.editExpenseReportData[index]
        this.date = data.date
        this.description = data.description
        this.hotel = data.hotel
        this.mie = data.mie
        this.other = data.other
        this.notes = data.notes
        // open the edit modal
        this.openEditBudgetModal()
      },
      // edit budget data
      editexpenseReportData () {
        // check if the details are empty
        if (this.date !== '' && this.description !== '' && this.hotel !== '' && this.mie !== '' && this.other !== '') {
          // hide the budget
          this.showExpenseReport = false

          // remove the last grand total row if its available
          this.deleteGrandTotalRow()

          // adding the data
          const rowData = {
            date: this.date,
            description: this.description,
            project: this.budgetSource,
            currency: this.currencyAcronym,
            hotel: this.hotel,
            mie: this.mie,
            other: this.other,
            notes: this.notes
          }
          
          // add it in the budget
          this.expenseReportData[this.selectedEditIndex] = rowData

          // calculate the grand total
          this.calculateGrandTotal()
          
          // show the budget
          this.showExpenseReport = true
          
          //  close the add a budget modal
          this.closeEditBudgetModal()
        }
      },
      // loads the stoemerald budget
      loadExpenseReport () {
        if (this.editExpenseReport !== null && this.editExpenseReport.length !== 0) {
          console.log('inside')
          this.showExpenseReport = false
          this.expenseReportData = this.editExpenseReport
          this.showExpenseReport = true
          this.getRequest(false)
        } else {
          // load it from the system
          this.getRequest(true)
          console.log('out')
        }
      },
      // clear the budget
      resetExpenseReport() {
        this.expenseReportData = []
        // get the expense report data
        this.getRequest(true)
      },
      // format the table data
      formatData (data) {
        var formattedData
        // check if it is a number
        if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
          // format the number to a currency format
          var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
          formattedData = currencyFormat.format(String(data)).substring(4)
        } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
          // check if the data is a date
          // formating the dates supplied
          var textDate = new Date(data)
          var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
          formattedData = formattedTextDate
        } else {
          formattedData = data
        }
        return formattedData
      },
      deleteGrandTotalRow () {
        // remove the grand total row last row
        const size = (this.expenseReportData.length - 1)
        this.expenseReportData.splice(size, 1)
      },
      // deletes an item at an index
      deleteItem (index) {
        this.showExpenseReport = false
        // remove the grand total row last row
        this.deleteGrandTotalRow()

        // remove the item
        this.expenseReportData.splice(index, 1)

        // insert the new grand total row
        this.calculateGrandTotal()

        this.showExpenseReport = true
      },
      // calculate the grand total
      calculateGrandTotal () {
        const size = this.expenseReportData.length
        if (size > 0) {
          // loop through the budget data
          var grandTotal = 0
          for (var i = 0; i < size; i++) {
            const rowTotal = parseFloat(this.expenseReportData[i].hotel) + parseFloat(this.expenseReportData[i].mie) + parseFloat(this.expenseReportData[i].other)
            grandTotal += rowTotal
          }
          const grandTotalRow = {
            date: '',
            description: '',
            project: '',
            currency: '',
            hotel: '',
            mie: '',
            other: 'Grand Total',
            notes: grandTotal
          }
          // push it into the budget data
          this.expenseReportData.push(grandTotalRow)
        }
      },
      // add budget data
      addexpenseReportData () {
        // check if the details are empty
        if (this.date !== '' && this.description !== '' && this.hotel !== '' && this.mie !== '' && this.other !== '') {
          // hide the expense report
          this.showExpenseReport = false
          // delete the grand total row
          this.deleteGrandTotalRow()

          // adding the data
          const rowData = {
            date: this.date,
            description: this.description,
            project: this.budgetSource,
            currency: this.currencyAcronym,
            hotel: this.hotel,
            mie: this.mie,
            other: this.other,
            notes: this.notes
          }
          
          // add it in the expense report
          this.expenseReportData.push(rowData)
          
          // calculate the grand total
          this.calculateGrandTotal()

          // show the expense report
          this.showExpenseReport = true
          
          //  close the add a expense report modal
          this.closeAddBudgetModal()
        }
      },
      back () {
        // moving to the next step
        this.$router.push('edit-activity-closure')
        // store the data
        // this.$store.commit('mutateBudget', this.expenseReportData)
      },
      next () {
        // if (this.expenseReportData.length !== 0) {
        //   // moving to the next step
        //   this.$router.push('activityReport')
        //   // store the data
        //   this.$store.commit('mutateExpenseReport', this.expenseReportData)
        // } else {
        //   this.isOk = false
        //   this.messageTitle = 'Error Message'
        //   this.message = 'You cannot move to the next step with an empty expense report...'
        //   this.$refs.messageModal.openMessageModal()
        // }
        this.$router.push('edit-liquidation-attachment')
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  