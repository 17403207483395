<template>
    <div class="PerDiemManagement">
      <PerDiemManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import PerDiemManagement from '@/components/PerDiemManagement.vue'
  
  export default {
    name: 'admin-PerDiemManagement-view',
    components: {
      PerDiemManagement
    }
  }
  </script>
  