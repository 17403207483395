<template>
    <div class="GoalManagement">
      <GoalManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import GoalManagement from '@/components/GoalManagement.vue'
  
  export default {
    name: 'goal-Management-view',
    components: {
      GoalManagement
    }
  }
  </script>
  