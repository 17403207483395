<template>
    <div>
      <EditParticipants/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EditParticipants from '@/components/EditParticipantPayments.vue'
  
  export default {
    name: 'participant-payments-view',
    components: {
      EditParticipants
    }
  }
  </script>
  