<template>
    <div class="DelegationReasons">
      <DelegationReasons/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import DelegationReasons from '@/components/DelegationReasons.vue'
  
  export default {
    name: 'delegation-reasons-view',
    components: {
      DelegationReasons
    }
  }
  </script>
  