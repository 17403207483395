<template>
    <div>
      <LiquidationAttachments/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import LiquidationAttachments from '@/components/LiquidationAttachments.vue'
  
  export default {
    name: 'liquidation-attachment-view',
    components: {
      LiquidationAttachments
    }
  }
  </script>
  