<template>
    <div class="VenueManagement">
      <VenueManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import VenueManagement from '@/components/VenueManagement.vue'
  
  export default {
    name: 'venue-Management-view',
    components: {
      VenueManagement
    }
  }
  </script>
  