<template>
    <div>
      <LiquidationHistory/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import LiquidationHistory from '@/components/LiquidationHistory.vue'
  
  export default {
    name: 'penidng-liquidation-view',
    components: {
      LiquidationHistory
    }
  }
  </script>
  