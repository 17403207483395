<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">>Request History</span> > View Request</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg  class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m10,23c0,.553-.447,1-1,1h-4c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h8c2.757,0,5,2.243,5,5v2c0,.553-.447,1-1,1s-1-.447-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h4c.553,0,1,.447,1,1ZM14,6c0-.553-.447-1-1-1H5c-.553,0-1,.447-1,1s.447,1,1,1h8c.553,0,1-.447,1-1Zm-4,5c0-.553-.447-1-1-1h-4c-.553,0-1,.447-1,1s.447,1,1,1h4c.553,0,1-.447,1-1Zm-5,4c-.553,0-1,.447-1,1s.447,1,1,1h2c.553,0,1-.447,1-1s-.447-1-1-1h-2Zm19,2c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-3.192-1.241l-2.223,2.134c-.144.141-.379.144-.522.002l-1.131-1.108c-.396-.388-1.028-.382-1.414.014-.387.395-.381,1.027.014,1.414l1.132,1.109c.46.449,1.062.674,1.663.674s1.201-.225,1.653-.671l2.213-2.124c.398-.383.411-1.016.029-1.414-.383-.4-1.017-.411-1.414-.029Z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                  <path d="m8,16c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-1.5-11.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm0,5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5ZM19,0H5C2.243,0,0,2.243,0,5v13c0,2.757,2.243,5,5,5h3c.552,0,1-.447,1-1s-.448-1-1-1h-3c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v9c0,.553.448,1,1,1s1-.447,1-1V5c0-2.757-2.243-5-5-5Zm-8,7h7c.552,0,1-.448,1-1s-.448-1-1-1h-7c-.552,0-1,.448-1,1s.448,1,1,1Zm0,5h7c.552,0,1-.448,1-1s-.448-1-1-1h-7c-.552,0-1,.448-1,1s.448,1,1,1Zm12.705,6.549c.391.578.391,1.324,0,1.902-.896,1.325-2.959,3.549-6.705,3.549s-5.809-2.224-6.706-3.549c-.391-.579-.391-1.325,0-1.902.896-1.325,2.958-3.549,6.705-3.549s5.809,2.224,6.705,3.549Zm-1.775.951c-.73-1.006-2.263-2.5-4.93-2.5s-4.201,1.495-4.93,2.5c.729,1.006,2.263,2.5,4.93,2.5s4.2-1.494,4.93-2.5Zm-4.93-1.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Z"/>
                </svg>
                <p class="text-left text-size-md font-medium text-gray-600">View Request</p>
              </div>
              <div class="flex-1 mt-4">
                <button
                  @click="switchView(0)"
                  :class=" currentView == 0 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out'">
                    View Memo
                </button>
                <button
                  @click="switchView(1)"
                  :class=" currentView == 1 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out'">
                    View Schedule
                </button>
                <button
                  @click="switchView(2)"
                  :class=" currentView == 2 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out'">
                    View Budget
                </button>
                <button
                  v-if="(egpafPayments !== null && egpafPayments.length > 0) || (mohPayments !== null && mohPayments.length > 0)"
                  @click="switchView(3)"
                  :class=" currentView == 3 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out'">
                    View Participant Payments
                </button>
                <button
                  v-if="uploadedFiles !== null && uploadedFiles.length > 0"
                  @click="switchView(4)"
                  :class=" currentView == 4 ? 'shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-600  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out'">
                    View Attachements
                </button>
              </div>
              <div class="mx-8 mb-5 mt-5 border shadow px-4 py-4 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-6 sm:gap-y-6  ">
                <div v-if="requestLoaded && currentView === 0 && memoData !== null" class="flex-1">
                  <!-- {{ memoData }} -->
                  <button
                    @click="downloadPr(4)"
                    class="shadow inline-block box-shadow mb-4 text-size-sm px-24 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out">
                      Download Purchase Requisition
                  </button>
                  <!-- <span class="text-size-md font-medium text-gray-500 text-left">Memo</span> -->
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer mb-4">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Item</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                      </tr>
                    </thead>
                      <tbody class="text-size-sm">
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Request Type</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.requestType.name }}</td>
                        </tr>
                       <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Title</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.title }}</td>
                        </tr>
                       <!--  <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Location</td>
                          <td class="py-3 px-2 border text-left"> {{ district }} </td>
                        </tr> -->
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Duration</td>
                          <td class="py-3 px-2 border text-left">{{ daysDifference }} Days</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Source of Funding</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.budgetSource.map((data) => data.name).join(', ') }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Activity Type</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.activityType.name }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Start Date & End Date</td>
                          <td class="py-3 px-2 border text-left"> {{ formatData(activityDates[0]) }} to {{ formatData(activityDates[(activityDates.length - 1)]) }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Selected Currency</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.currency.name }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Specific Objectives</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.specificObjectives.map((data) => data.name).join(', ') }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Expected Participants</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.expectedParticipants }} Participant(s)</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Accommodation Booking</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.accommodation.name }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Conference Booking</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.conferencing.name }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Transportation Booking</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.transportation.name }}</td>
                        </tr>
                        <tr class=" text-gray-600" style="">
                          <td class="py-3 px-2 border text-left font-medium">Additional Notes</td>
                          <td class="py-3 px-2 border text-left">{{ memoData.notes }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div  v-if="requestLoaded && currentView === 1 && scheduleData !== null" class="flex-1 mb-4">
                  <!-- <span class="text-size-md font-medium text-gray-500">Schedule</span> -->
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class="bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Start Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">End Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Place/Site</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in scheduleData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.startDate) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.endDate) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.place.name }}</td>
                      </tr>
                    </tbody>
                </table>
                </div>
                <div  v-if="requestLoaded && currentView === 2"  class="flex-1 mb-4 space-y-6">
                  <!-- the egpaf budget data -->
                  <table v-if="egpafBudgetData !== null && egpafBudgetData.length !== 0" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 text-left border-l text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Transport Refund</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">No of Employees</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">75% Advance </td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">100% Total ({{ currency.name }})</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in egpafBudgetData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        
                        <td v-if="i !== (egpafBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.breakfast.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td v-if="i !== (egpafBudgetData.length - 1)"  class="py-3 px-2 border text-left">{{ formatData(data.lunch.amount) }}</td>
                        <td v-else  class="py-3 px-2 border text-left"></td>

                        <td v-if="i !== (egpafBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.dinner.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td v-if="i !== (egpafBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.Incidental.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>

                        <td :class="[ i != (egpafBudgetData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.staffNo) }}</td>
                        <td :class="[ i != (egpafBudgetData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total * 0.75) }}</td>
                        <td :class="[ i != (egpafBudgetData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total) }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- the moh budget data -->
                  <table v-if="mohBudgetData !== null && mohBudgetData.length !== 0" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 text-left border-l text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Transport Refund</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">No of People</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">100% Total ({{ currency.name }})</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in mohBudgetData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        
                        <td v-if="i !== (mohBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.breakfast.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td v-if="i !== (mohBudgetData.length - 1)"  class="py-3 px-2 border text-left">{{ formatData(data.lunch.amount) }}</td>
                        <td v-else  class="py-3 px-2 border text-left"></td>

                        <td v-if="i !== (mohBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.dinner.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td v-if="i !== (mohBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.transport) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>

                        <td v-if="i !== (mohBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.staffNo) }}</td>
                        <td v-else class="py-3 px-2 border font-medium text-xs text-left">{{ data.places }} </td>
                        
                        <td :class="[ i != (mohBudgetData.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total) }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- the unit cost budget -->
                  <table v-if="unitBudgetData !== null && unitBudgetData.length !== 0" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">Date</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Description</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Cost</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Quantity</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Total ({{ currency.name }})</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in unitBudgetData" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ formatData(data.date) }}</td>
                        <td class="py-3 px-2 border text-left">{{ data.description }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.cost) }}</td>
                        
                        <td v-if="i !== (unitBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.quantity) }}</td>
                        <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.quantity) }}</td>

                        <td v-if="i !== (unitBudgetData.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>
                        <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>

                      </tr>
                    </tbody>
                </table>

                </div>
                
                <!-- shows the attached files -->
                <div  v-if="requestLoaded && currentView === 3"  class="flex-1 mb-4 space-y-6">
                  <div class="flex">
                    <div class="mx-auto space-x-0.5">
                      <button
                        @click="downloadMpesaXML()"
                        class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out">
                          Download Mpesa XML
                      </button>
                      <button
                        @click="downloadMpesaCSV()"
                        class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out">
                          Download Mpesa CSV
                      </button>
                      <button
                        @click="downloadBankList(4)"
                        class="shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out">
                          Download Bank List
                      </button>
                    </div>
                  </div>

                  <!-- egpaf employee payments-->
                  <!-- {{ egpafPayments }} -->
                  <table v-if="egpafPayments !== null && egpafPayments.length > 0" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">EGPAF Employee</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Transportation Refund</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in egpafPayments" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.employee.firstname }} {{ data.employee.surname }}</td>
                        
                        <td v-if="i !== (egpafPayments.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.breakfast.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.breakfastDays }} </td>
                        
                        <td v-if="i !== (egpafPayments.length - 1)"  class="py-3 px-2 border text-left">{{ formatData(data.lunch.amount) }}</td>
                        <td v-else  class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.lunchDays }} </td>

                        <td v-if="i !== (egpafPayments.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.dinner.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.dinnerDays }} </td>

                        <td v-if="i !== (egpafPayments.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.Incidental.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.incidentalDays }} </td>

                        <td :class="[ i != (egpafPayments.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.transport) }}</td>
                        <td :class="[ i != (egpafPayments.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total) }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- moh & other employee payments -->
                  <!-- {{ mohPayments }} -->
                  <table v-if="mohPayments !== null && mohPayments.length > 0" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">MoH/Other Employee</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Breakfast Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Lunch Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Dinner Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Incidental Days</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Transportation Refund</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in mohPayments" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ data.employee.firstname }} {{ data.employee.surname }}</td>
                        
                        <td v-if="i !== (mohPayments.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.breakfast.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.breakfastDays }} </td>
                        
                        <td v-if="i !== (mohPayments.length - 1)"  class="py-3 px-2 border text-left">{{ formatData(data.lunch.amount) }}</td>
                        <td v-else  class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.lunchDays }} </td>

                        <td v-if="i !== (mohPayments.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.dinner.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>
                        
                        <td class="py-3 px-2 border text-left"> {{ data.dinnerDays }} </td>

                        <td v-if="i !== (mohPayments.length - 1)" class="py-3 px-2 border text-left">{{ formatData(data.Incidental.amount) }}</td>
                        <td v-else class="py-3 px-2 border text-left"></td>

                        <td class="py-3 px-2 border text-left"> {{ data.incidentalDays }} </td>

                        <td :class="[ i != (mohPayments.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.transport) }}</td>
                        <td :class="[ i != (mohPayments.length - 1) ? 'py-3 px-2 border text-left' : 'py-3 px-2 border font-medium text-xs text-left']">{{ formatData(data.total) }}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                
                <!-- shows the attached files -->
                <div  v-if="requestLoaded && currentView === 4"  class="flex-1 mb-4 space-y-4">
                  <!-- <span class="text-size-md font-medium text-gray-500">Budget</span> -->
                  <button
                    @click="downloadAllAttachments(4)"
                    class="shadow inline-block box-shadow text-size-sm px-24 py-2 bg-emerald-500  text-white font-medium leading-tight focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-600 transition duration-150 ease-in-out">
                      Download All Attachements
                  </button>
                  
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">No</td>
                        <td class="py-3 px-2 border-l text-left text-white font-bold">File Name</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">File Size</td>
                        <td class="py-3 px-2 border-l text-center text-white font-bold">Download</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in uploadedFiles" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ (i + 1) }}</td>
                        <td class="py-3 px-2 border text-left">{{ formatData(data.realName) }}</td>
                        <td class="py-3 px-2 border text-center">{{ fileSizeFormatter(data.size) }}</td>
                        <td @click="downloadAttachments(data.realName, data.name)" class="py-3 px-2 border text-center">
                          <svg class="w-5 h-5 mx-auto fill-emerald-600" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                            <path d="m21,9v9h-6.329l-1,1h-3.343l-1-1H3v-9h8v4.034l-1.793-1.792-1.414,1.414,2.881,2.881c.366.365.846.549,1.326.549s.96-.184,1.326-.549l2.881-2.881-1.414-1.414-1.793,1.792v-4.034h8Zm-8-3h-2v3h2v-3Zm4-2h-2v2h2v-2Zm-4-3h-2v2h2V1Zm6-1h-2v2h2V0ZM7,0h-2v2h2V0Zm2,4h-2v2h2v-2Zm-4.5,3L2.083,0H0l2.417,7h2.083Zm17.083,0L24,0h-2.083s-2.417,7-2.417,7h2.083Zm-13.083,13H1v2c0,1.105.895,2,2,2h18c1.105,0,2-.895,2-2v-2h-7.5l-1,1h-5l-1-1Z"/>
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loader modal -->
      <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  import Sidebar from '@/components/SideBar.vue'
  import LoadingModal from './LoadingModal.vue'
  import MessageModal from './MessageModal.vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { concat } from 'rxjs'

  export default {
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token',
        'district',
        'officeId',
      ])
    },
    components: {
      Navbar,
      Sidebar,
      LoadingModal,
      MessageModal
    },
    setup () {
      return {
      }
    },
    name: 'view-approved-request',
    props: {
    },
    data: () => {
      return {
        currentView: 0,
        expandBody: false,
        memoData: null,
        scheduleData: null,
        egpafBudgetData: null,
        mohBudgetData: null,
        unitBudgetData: null,
        uploadedFiles: null,
        currency: {},
        egpafPayments: null,
        mohPayments: null,
        activityDates: null,
        daysDifference: '',
        requestLoaded: false,
        BASE_URL: process.env.VUE_APP_BASE_URL,
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    mounted () {
      // get the selected request
      this.getRequest()
      // removing the submenu items selection
      // this.$store.commit('mutateActiveListItemIndex', 4)
      // this.$store.commit('mutateSelectedSubMenuItem', null)
    },
    methods: {
      // download the mpesa xml document
      downloadMpesaXML () {
        const title = this.memoData.title
        const randomCode = (Math.random() + 1).toString(36).substring(7)
        const filename = title.toLocaleLowerCase().trim().replace(/ /g, '-') + '-' + randomCode

        const requestId = localStorage.getItem('requestId')

        this.$refs.loadingModal.openLoaderModal()
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/download-mpesa-xml?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            console.log(response)
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the attached files.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      // download the mpesa csv document
      downloadMpesaCSV () {
        const title = this.memoData.title
        const randomCode = (Math.random() + 1).toString(36).substring(7)
        const filename = title.toLocaleLowerCase().trim().replace(/ /g, '-') + '-' + randomCode

        const requestId = localStorage.getItem('requestId')

        this.$refs.loadingModal.openLoaderModal()
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/download-mpesa-csv?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            console.log(response)
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the attached files.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      // download the mpesa xml document
      downloadBankList () {
        const title = this.memoData.title
        const randomCode = (Math.random() + 1).toString(36).substring(7)
        const filename = title.toLocaleLowerCase().trim().replace(/ /g, '-') + '-' + randomCode

        const requestId = localStorage.getItem('requestId')

        this.$refs.loadingModal.openLoaderModal()
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/download-bank-list?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            console.log(response)
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the attached files.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      // download the pr document
      downloadPr () {
        this.$refs.loadingModal.openLoaderModal()
        const requestId = localStorage.getItem('requestId')
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/downloadPR?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = this.memoData.title 
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the attached files.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      // file size converter
      fileSizeFormatter (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) {
          return '0 Bytes'
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
      },
      downloadAllAttachments () {
        this.$refs.loadingModal.openLoaderModal()

        const numberOfFiles = this.uploadedFiles.length

        const observables = []

        for (let i = 0; i < numberOfFiles; i++) {
          const file = this.uploadedFiles[i]
          const generatedFilename = file.name
          const realFilename = file.realName
          const fileObservable = ajax({
            method: 'GET',
            url: this.BASE_URL + '/request/downloadAttachment?filename=' + generatedFilename,
            headers: {
              Authorization: `Bearer ${this.token}`,
              filename: realFilename
            },
            responseType: 'blob'
          })

          observables.push(fileObservable)
        }

        const observable = concat(...observables)

        
        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = response.request.headers.filename
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the attached files.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      downloadAttachments (originalFilename, generatedFilename) {
        this.$refs.loadingModal.openLoaderModal()
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/downloadAttachment?filename=' + generatedFilename,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: 'blob'
        })

        const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            console.log(response)
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originalFilename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to download the attached files.'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {
            this.$refs.loadingModal.closeLoaderModal()
          }
        }

        observable.subscribe(observer)
      },
      getRequest () {
        const requestId = localStorage.getItem('requestId')
        const observable = ajax({
          method: 'GET',
          url: this.BASE_URL + '/request/findById?id=' + requestId,
          headers: {
            Authorization: `Bearer ${this.token}`
          },
        })
        const observer = {
          next: (response) => {
            const data = response.response[0]
            this.memoData = data.memo
            this.scheduleData = data.schedule
            this.egpafBudgetData = data.budget[0].data
            this.mohBudgetData = data.budget[1].data
            this.unitBudgetData = data.budget[2].data
            this.uploadedFiles = data.attachment
            this.egpafPayments = data.payment[0].data
            this.mohPayments = data.payment[1].data

            // // get the currency
            // this.currency = this.memo.currency
            // get the start and end date
            this.startEndDate()
            // get the activity dates
            this.getdaysDifference()

            this.requestLoaded = true
          },
          error: () => {},
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      // calculates the days difference
      getdaysDifference () {
        try {
          const size = this.activityDates.length
          const startDate = this.activityDates[0]
          const endDate = this.activityDates[(size - 1)]
          const date1 = new Date(startDate);
          const date2 = new Date(endDate);
          const diffTime = Math.abs(date2 - date1);
          this.daysDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        } catch (err) {
          console.log(err)
        }  
      },
      // get the start date and end date
      startEndDate() {
        const data = this.scheduleData
        const size = data.length

        var dates = []
        for (let i = 0; i < size; i++) {
          const startDate = data[i].startDate
          const endDate = data[i].endDate

          dates.push(startDate)
          dates.push(endDate)
        }

        // remove duplicate dates
        dates = [... new Set(dates)]
        
        // sort the dates
        dates.sort()

        // set the date
        this.activityDates = dates
      },
      // format the table data
      formatData (data) {
        var formattedData
        // check if it is a number
        if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
          // format the number to a currency format
          var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
          formattedData = currencyFormat.format(String(data)).substring(4)
        } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
          // check if the data is a date
          // formating the dates supplied
          var textDate = new Date(data)
          var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
          formattedData = formattedTextDate
        } else {
          formattedData = data
        }
        return formattedData
      },
      // switches the activity summary being viewed
      switchView (index) {
        this.currentView = index
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  