<template>
  <div v-if="canShow" id="snackbar" class="flex items-center justify-between shadow-2xl border-l-red-400 border-l-2 rounded-l-sm shadow-inherit">
    <!-- <svg id="Layer_1" class="h-6 w-6 animate-spin fill-current" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m12 5a1.5 1.5 0 0 1 -1.5-1.5v-2a1.5 1.5 0 0 1 3 0v2a1.5 1.5 0 0 1 -1.5 1.5zm1.5 17.5v-2a1.5 1.5 0 0 0 -3 0v2a1.5 1.5 0 0 0 3 0zm-8.5-10.5a1.5 1.5 0 0 0 -1.5-1.5h-2a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 0 1.5-1.5zm19 0a1.5 1.5 0 0 0 -1.5-1.5h-2a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 0 1.5-1.5zm-6.524-6.671.981-1.743a1.5 1.5 0 0 0 -2.613-1.473l-.982 1.743a1.5 1.5 0 0 0 .571 2.044 1.484 1.484 0 0 0 .735.194 1.5 1.5 0 0 0 1.308-.765zm-9.32 16.558.982-1.743a1.5 1.5 0 0 0 -2.614-1.473l-.981 1.743a1.5 1.5 0 0 0 2.613 1.473zm-2.256-13.32a1.5 1.5 0 0 0 -.57-2.043l-1.744-.981a1.5 1.5 0 0 0 -1.473 2.613l1.743.982a1.5 1.5 0 0 0 2.044-.571zm16.558 9.32a1.5 1.5 0 0 0 -.57-2.043l-1.743-.982a1.5 1.5 0 0 0 -1.473 2.614l1.743.981a1.5 1.5 0 0 0 2.043-.57zm-13.891-11.987a1.5 1.5 0 0 0 .571-2.043l-.982-1.744a1.5 1.5 0 0 0 -2.613 1.473l.981 1.743a1.5 1.5 0 0 0 1.308.764 1.484 1.484 0 0 0 .735-.193zm9.32 16.558a1.5 1.5 0 0 0 .57-2.043l-.981-1.743a1.5 1.5 0 0 0 -2.614 1.473l.982 1.743a1.5 1.5 0 0 0 2.043.57zm2.257-13.32 1.743-.982a1.5 1.5 0 0 0 -1.473-2.613l-1.743.981a1.5 1.5 0 0 0 1.473 2.614zm-16.558 9.319 1.743-.981a1.5 1.5 0 0 0 -1.473-2.614l-1.743.982a1.5 1.5 0 0 0 .738 2.806 1.483 1.483 0 0 0 .735-.193z"/></svg> -->
    <div class="flex items-center">
      <svg class="h-6 w-6 fill-current animate-pulse" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23.91,17.05l-1.65-7.82c-1.03-3.67-4.42-6.24-8.26-6.24-4.05,0-7.49,2.74-8.37,6.69l-1.55,7.43c-.21,.95,.02,1.93,.63,2.69,.61,.76,1.52,1.19,2.49,1.19h3.35c.24,1.69,1.69,3,3.45,3s3.21-1.31,3.45-3h3.35c.93,0,1.79-.39,2.39-1.07l.16-.19c.61-.8,.81-1.82,.56-2.69Zm-2.93,.86h0c-.04,.06-.09,.09-.16,.09H7.2c-.07,0-.12-.02-.16-.07-.04-.05-.05-.1-.03-.17l1.55-7.43c.57-2.55,2.81-4.33,5.44-4.33,2.5,0,4.7,1.66,5.35,3.95l1.65,7.82c.01,.05,0,.1-.02,.15ZM5.26,5.61c-.73,1.08-1.29,2.32-1.59,3.67l-1.54,7.41c-.08,.35-.12,.71-.12,1.06-.5-.2-.95-.51-1.3-.94-.61-.76-.84-1.74-.63-2.69L1.62,6.69C2.51,2.74,5.95,0,10,0c1.43,0,2.81,.36,4.02,1,0,0-.01,0-.02,0-3.26,0-6.2,1.46-8.15,3.83-.02,.02-.03,.04-.05,.06-.16,.19-.31,.39-.45,.6-.03,.05-.06,.09-.09,.13Z"/></svg>
      <span class=" ml-1"> {{ message }}</span>
    </div>
    <!-- <svg class="h-6 w-6 fill-current cursor-pointer" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m15.707,9.707l-2.293,2.293,2.293,2.293c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293,2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.293,2.293,2.293-2.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Zm8.293,2.293c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"/></svg> -->
    <svg @click="close()" class="h-5 w-5 fill-current cursor-pointer" xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24" width="512" height="512"><polygon points="19.061 7.061 16.939 4.939 12 9.879 7.061 4.939 4.939 7.061 9.879 12 4.939 16.939 7.061 19.061 12 14.121 16.939 19.061 19.061 16.939 14.121 12 19.061 7.061"/></svg>

  </div>
</template>

<script>

export default {
  components: {
  },
  setup () {
    return {
    }
  },
  name: 'pop-up',
  props: {
    icon: Number,
    message: String
  },
  data: () => {
    return {
      canShow: false
    }
  },
  mounted () {
  },
  methods: {
    show () {
      this.canShow = true
      setTimeout(() => { 
        this.canShow = false
      }, 1000 * 10)
    },
    close () {
      this.canShow = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
#snackbar {
  /* visibility: hidden; */
  min-width: 300px;
  margin-left: -120px;
  margin-right: auto;
  background-color: rgba(16, 185, 129, 1);
  color: #fff;
  text-align: center;
  /* border-radius: 0px; */
  padding: 12px;
  position: fixed;
  align-items: center;
  display: flex;
  z-index: 2;
  left: 87%;
  bottom: 30px;
  font-size: 0.8em;
  box-shadow: 20px 20px 20px 20px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
</style>
