<template>
    <div class="FundingManagement">
      <FundingManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import FundingManagement from '@/components/FundingManagement.vue'
  
  export default {
    name: 'admin-FundingManagement-view',
    components: {
      FundingManagement
    }
  }
  </script>
  