<template>
    <div class="timeline">
      <Signature/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Signature from '@/components/Signature.vue'
  
  export default {
    name: 'signature-view',
    components: {
      Signature
    }
  }
  </script>
  