<template>
    <div class="w-full h-screen bg-white overflow-x-hidden">
      <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
      <div>
        <Sidebar class="sidebar"/>
        <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
          <!-- the top route and username grid -->
          <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
            <span><span class=" text-emerald-500">Home</span>> Request Timeline</span>
            <span>{{ firstname }} {{ surname }}</span>
          </div>
            <div>
            <!-- Timeline -->
            <div class=" shadow-md mb-8 text-gray-400 col-span-full border hover:shadow-lg bg-white ">
              <!-- The chart title -->
              <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
                <!-- <svg  class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
                <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,20c0,2.206-1.794,4-4,4H7.854l2.045-2h10.102c1.103,0,2-.897,2-2s-.897-2-2-2h-5c-2.206,0-4-1.794-4-4,0-1.927,1.37-3.539,3.187-3.916l1.959,1.916h-1.146c-1.103,0-2,.897-2,2s.897,2,2,2h5c2.206,0,4,1.794,4,4Zm-1.465-11.464c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878ZM8.535,20.536c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878Z"/></svg>
                <p class="text-left text-size-md font-medium text-gray-600">Request Timeline</p>
              </div>
              <!-- The user request chart -->
              <div class="ml-8 pb-4">
                <div
                  class=" ring-1 pt-2 ring-gray-200 shadow-md mr-10 chart-height"
                  width="100%">
                  <div v-if="isWithdrawn" class="mt-2">
                    <span class=" text-red-500 text-size items-baseline font-bold">WITHDRAWN REQUEST</span>
                  </div>
                  <div class=" flex sm:justify-start md:justify-center overflow-x-auto">
                    <div class="flex px-4 space-x-8">
                      <!-- {{ trail }} -->
                      <div class="flex-1 py-6">
                        <!-- looping through the approvers -->
                        <!-- <div v-for="(data, i) in trail" :key="i"> -->
                        <div v-for="(data, i) in trail.length" :key="i">
                          <!-- approval icon -->
                          <svg v-if="data.status == 'Approved' || data.status == 'Submitted'" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-.091,15.419c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701,1.404,1.425-5.793,5.707Z"/></svg>
                          <!-- rejection icon -->
                          <svg v-else-if="data.status == 'Rejected'" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                          <!-- pending icon -->
                          <svg v-else class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z"/></svg>
                          <!-- {{ (i) }} {{ (trail.length - 1) }} -->
                          <!-- the line -->
                          <div v-if=" i < (trail.length - 1)" class=" w-0.5 h-48 bg-emerald-600 ml-2 my-0.5"></div>
                          <!-- <svg class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z"/></svg> -->
                          <!-- the line -->
                          <!-- <div class=" w-0.5 h-48 bg-emerald-400 ml-2 my-0.5"></div> -->
                          <!-- <svg class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="arrow-circle-down" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,16a2.993,2.993,0,0,1-1.987-.752c-.327-.291-.637-.574-.84-.777L6.3,11.647a1,1,0,0,1,1.4-1.426L10.58,13.05c.188.187.468.441.759.7a1,1,0,0,0,1.323,0c.29-.258.57-.512.752-.693L16.3,10.221a1,1,0,1,1,1.4,1.426l-2.879,2.829c-.2.2-.507.48-.833.769A2.99,2.99,0,0,1,12,16Z"/></svg> -->
                          <!-- <svg class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z"/></svg> -->
                          <!-- the line -->
                          <!-- <div class=" h-0.5 w-80 bg-emerald-400"></div> -->
                        </div>
                      </div>
                      <div v-if="showTrail" class="flex-1 py-4">
                        <!-- <div v-for="(data, i) in trail" :key="i"> -->
                        <div v-for="(data, i) in trail" :key="i">
                          <!-- {{ i }} -->
                          <div :class="i==0 ? 'py-2 text-size-md mt-3 h-32 shadow-md hover:shadow-md border border-gray-200' : 'py-2 text-size-md h-32 shadow-md hover:shadow-md border border-gray-200 my-20'" style="width: 38rem">
                            <div class="mx-2 w-full text-center text-gray-400 space-y-0.5 grid">
                              <div class="space-x-1 text-left w-full items-center mt-1.5 justify-between flex">
                                <div>
                                  <span class="font-medium text-gray-500 w-20 mr-6">Name:</span>
                                  <span class="w-60">{{ data.name }}</span>
                                </div>
                                <!-- <svg class="h-4 w-4 -translate-x-6 fill-emerald-700" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m8.009,23h-.006c-.803-.002-1.557-.316-2.123-.887L.311,16.502l1.42-1.408,5.569,5.611c.188.189.439.295.707.295h.002c.267,0,.519-.104.708-.293l13.577-13.577,1.414,1.414-13.577,13.577c-.567.567-1.32.879-2.122.879Zm2.14-11.897L18.349,2.902l-1.414-1.414-8.2,8.2c-.19.191-.404.307-.713.293-.269-.001-.521-.108-.709-.301L3.235,5.516l-1.429,1.399,4.077,4.165c.565.577,1.32.897,2.127.902h.017c.801,0,1.554-.312,2.121-.879Z"/></svg> -->
                                <!-- {{ data.status }} -->
                                <svg :class=" data.status !== 'Denied' && data.status !== 'Pending'  ? 'h-4 w-4 -translate-x-6 fill-emerald-700' : 'hidden'" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m6.01,11.26L1.923,7.044l2.154-2.088,3.713,3.831L16.039.539l2.121,2.121-8.607,8.607c-.472.472-1.104.732-1.772.732-.67-.001-1.298-.264-1.77-.739Zm15.387-5.08l-13.611,13.611-5.221-5.266-2.13,2.113,5.574,5.62c.472.476,1.1.738,1.77.74.667,0,1.301-.26,1.773-.732l13.966-13.966-2.121-2.121Z"/></svg>
                                <svg :class=" data.status === 'Denied'  ? 'h-4 w-4 -translate-x-6 fill-red-500' : 'hidden'" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12h12V12C24,5.383,18.617,0,12,0Zm9,21H12c-4.963,0-9-4.037-9-9S7.037,3,12,3s9,4.037,9,9v9Zm-4.189-11.689l-2.689,2.689,2.689,2.689-2.121,2.121-2.689-2.689-2.689,2.689-2.121-2.121,2.689-2.689-2.689-2.689,2.121-2.121,2.689,2.689,2.689-2.689,2.121,2.121Z"/></svg>
                                <svg :class=" data.status === 'Pending'  ? 'h-4 w-4 -translate-x-6 fill-emerald-600' : 'hidden'" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                                  <path d="m11.923,17.806c.108.268.076.572-.085.811-.162.24-.431.383-.72.383h-2.131s-2.132,0-2.132,0c-.289,0-.558-.144-.72-.383-.161-.239-.193-.543-.085-.811.367-.908,1.073-1.781,2.099-2.592l.3-.237c.316-.25.765-.249,1.08.002l.294.235c1.027.816,1.734,1.689,2.1,2.593Zm4.93,2.218c-.681-.471-1.615-.303-2.087.38-.259.374-.683.596-1.135.596H4.368c-.401,0-.782-.176-1.046-.481-.147-.172-.385-.537-.308-1.048.337-2.244,1.746-4.361,4.188-6.294.359-.285.569-.718.569-1.176s-.21-.892-.569-1.176c-2.442-1.933-3.852-4.051-4.188-6.295-.077-.511.16-.875.308-1.047.264-.306.645-.481,1.046-.481h9.264c.4,0,.781.174,1.044.479.54.626,1.486.697,2.115.156.628-.541.697-1.488.156-2.116-.833-.966-2.042-1.52-3.315-1.52H4.368C3.093,0,1.883.556,1.049,1.524.227,2.479-.139,3.737.048,4.975c.382,2.542,1.704,4.899,3.936,7.025C1.752,14.126.43,16.483.048,19.025c-.187,1.238.179,2.496,1.001,3.451.834.969,2.044,1.524,3.319,1.524h9.264c1.437,0,2.783-.706,3.602-1.889.472-.681.302-1.616-.38-2.087Zm7.146-8.024c0,3.314-2.686,6-6,6s-6-2.686-6-6,2.686-6,6-6,6,2.686,6,6Zm-3.5,1.086l-1.5-1.5v-2.586c0-.552-.448-1-1-1h0c-.552,0-1,.448-1,1v2.586c0,.53.211,1.039.586,1.414l1.5,1.5c.391.39,1.024.39,1.414,0,.391-.391.391-1.024,0-1.414Z"/>
                                </svg>
                                <!-- <svg :class=" data.status !== 'Pending' ? 'h-4 w-4 -translate-x-6 fill-emerald-700' : 'h-4 w-4 -translate-x-6 fill-gray-400'" xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24" width="512" height="512"><path d="M7.8,21.425A2.542,2.542,0,0,1,6,20.679L.439,15.121,2.561,13,7.8,18.239,21.439,4.6l2.122,2.121L9.6,20.679A2.542,2.542,0,0,1,7.8,21.425Z"/></svg> -->
                              </div>

                              <div v-if="i==0" class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Title:</span>
                                <span class="w-60">{{ data.title }}</span>
                              </div>
                              
                              <div class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Position:</span>
                                <span class="w-60">{{ data.position }}</span>
                              </div>

                              <div class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Date:</span>
                                <span class="w-60">{{ data.date }}</span>
                              </div>

                              <div class="space-x-1 text-left w-full flex items-center mt-1.5">
                                <span class="font-medium text-gray-500 w-14">Status:</span>
                                <span class="w-60 ">{{ data.status }}</span>
                              </div>
                              
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 mb-10">
                <div class="flex-1 mb-5 space-x-0.5">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loader modal -->
      <LoadingModal
        ref="loadingModal"
      />
      <!-- message feedback modal -->
      <MessageModal
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        ref="messageModal"
      />
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/AdminNavbar.vue'
  import Sidebar from '@/components/AdminSideBar.vue'
  import LoadingModal from './LoadingModal.vue'
  import MessageModal from './MessageModal.vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { retry, switchMap } from 'rxjs'
  
  export default {
    computed: {
      ...mapState([
        'id',
        'firstname',
        'surname',
        'token'
      ])
    },
    components: {
      Navbar,
      Sidebar,
      LoadingModal,
      MessageModal
    },
    setup () {
      return {
      }
    },
    name: 'timeline-component',
    props: {
    },
    data: () => {
      return {
        showTrail: false,
        trail: [],
        activityId: null,
        selectedRequest: [],
        isWithdrawn: false,
        requestData: null,
        message: '',
        messageTitle: '',
        idOk: false,
        expandBody: false,
        fullApproved: true,
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
      // removing the submenu items selection
      // this.$store.commit('mutateActiveListItemIndex', 3)
      // this.$store.commit('mutateSelectedSubMenuItem', null)
      this.findTrailById()
      // clear the request vuex data
      this.clearRequestVuex()
    },
    methods: {
      clearRequestVuex () {
        this.$store.commit('mutateEditMemo', null)
        this.$store.commit('mutateEditMoHBudget', null)
        this.$store.commit('mutateEditEgpafBudget', null)
        this.$store.commit('mutateEditUnitBudget', null)
        this.$store.commit('mutateEditMoHPayments', null)
        this.$store.commit('mutateEditEgpafPayments', null)
        this.$store.commit('mutateEditSchedule', null)
        this.$store.commit('mutateEditUploadedFiles', [])
        this.$store.commit('mutateEditPr', null)
      },
      // get the selected request's data
      getRequest () {
        const requestId = this.selectedRequest.id
        const observable = ajax({
          url: this.BASE_URL + '/request/findById?id=' + requestId,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        const observer = {
          next: (response) => {
            const data = response.response
            this.requestData = data
          },
          error: () => {},
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      // resubmits a travel request
      resubmit () {
        // recreating the memo data
        this.$store.commit('mutateEditMemo', this.requestData[0].memo)

        // recreating the budget data
        const budgets = this.requestData[0].budget
        const egpafBudget = budgets[0].data
        const mohBudget = budgets[1].data
        const unitBudget = budgets[2].data
        
        this.$store.commit('mutateEditEgpafBudget', egpafBudget)
        this.$store.commit('mutateEditMoHBudget', mohBudget)
        this.$store.commit('mutateEditUnitBudget', unitBudget)

        // recreating the participants payment
        const payments = this.requestData[0].payment
        const egpafPayment = payments[0].data
        const mohPayment = payments[1].data

        this.$store.commit('mutateEditEgpafPayments', egpafPayment)
        this.$store.commit('mutateEditMoHPayments', mohPayment)

        // recreating the schedule
        const schedule = this.requestData[0].schedule
        this.$store.commit('mutateEditSchedule', schedule)

        // recreating the uploaded files
        const uploadedFiles = this.requestData[0].attachment
        this.$store.commit('mutateEditUploadedFiles', uploadedFiles)

        // getting the request id
        const requestId = this.requestData[0].id
        this.$store.commit('mutateEditRequestId', requestId)

        // recreating the pr
        const editPr = this.requestData[0].pr
        this.$store.commit('mutateEditPr', editPr)
        

        this.$router.push('edit-memo')
      },
      // check if the request was withdrawn
      withdrawCheck () {
        const requestId = this.selectedRequest.id
        const observable = ajax({
          url: this.BASE_URL + '/unsubmit/findByRequestId?id=' + requestId,
          method: 'GET',
          headers: {
              Authorization: `Bearer ${this.token}`
            }
        })

        const observer = {
          next: (response) => {
            const data = response.response
            const size = data.length

            if (size> 0) {
              this.isWithdrawn = true
            }
          },
          error: () => {},
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      // withdraws the request from the approval process
      withdraw () {
        // open the loading modal
        this.$refs.loadingModal.openLoaderModal()

        const requestId = this.selectedRequest.id
        const observable = ajax({
          url: this.BASE_URL + '/unsubmit/insert',
          method: 'POST',
          body: {
            request_id: requestId
          },
          headers: {
              Authorization: `Bearer ${this.token}`
            }
        })

        const observer = {
          next: () => {
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.message = 'Your request has been withdrawn approvers will not longer be able to approve it...'
            this.$refs.messageModal.openMessageModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loadingModal.closeLoaderModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.message = 'Failed to submit your request withdrawal, please try again later...'
            this.$refs.messageModal.openMessageModal()
          },
          complete: () => {}
        }

        observable.subscribe(observer)
      },
      // expands or collapses the main body
      expand (value) {
        this.expandBody = value
      },
      // get the activity status
      findTrailById () {
        const requestId = localStorage.getItem("trackRequestId")
        if (requestId !== undefined) {
          this.fullApproved = true
          this.showTrail = false
          const observable = ajax({
            url: this.BASE_URL + '/request/findTrail?id=' + requestId,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }).pipe(
            retry(1)
          )
          const observer = {
            next: (response) => {
              const data = response.response
              this.trail = data
              
              // check if the request was withdrawn
              this.withdrawCheck()

              // get the request data
              this.getRequest()

              // loop through the data & check if it was fully approved
              var approved = true
              const size = data.length

              for (let i = 1; i < size; i++) {
                const trailData = data[i]
                const status = trailData.status

                if (status !== 'Approved') {
                  approved = false
                }
              }

              this.fullApproved = approved
            },
            error: (err) => {
              const status = err.status
              if (status === 401) {
                this.$router.push('/')
              }
            },
            complete: () => {
              this.showTrail = true
            }
          }
          observable.subscribe(observer)
        } else {
          this.trail = []
        }
      },
      // finds the latest request trail
      // get the activity status
      findTrail () {
        this.fullApproved = true
        this.showTrail = false
        const observable = ajax({
          url: this.BASE_URL + '/request/findByEmployeeId?id=' + this.id,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        }).pipe(
          retry(),
          switchMap((value) => {
            const requests = value.response
            const size = requests.length
            if (size > 0) {
              // get the request id of the last request
              const id = requests[size - 1].id
              return  ajax ({
                url: this.BASE_URL + '/request/findTrail?id=' + id,
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${this.token}`
                }
              })
            } else {
              return null
            }
          })
        )        

        const observer = {
          next: (response) => {
            const data = response.response
            const id = data[0].id
            const title = data[0].title
            const date = data[0].date
            const name = title + ', ' + date
            this.selectedRequest = { id: id, name: name }
            this.trail = data

            // check if the request was withdrawn
            this.withdrawCheck()

            // get the request data
            this.getRequest()

            // loop through the data & check if it was fully approved
            var approved = true
            const size = data.length

            for (let i = 1; i < size; i++) {
              const trailData = data[i]
              const status = trailData.status

              if (status !== 'Approved') {
                approved = false
              }
            }

            this.fullApproved = approved
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {
            this.showTrail = true
          }
        }

        observable.subscribe(observer)

        // if (requestId !== undefined) {
        //   this.showTrail = false
        //   const observable = ajax({
        //     url: this.BASE_URL + '/request/findTrail?id=' + requestId,
        //     method: 'GET',
        //     headers: {
        //       Authorization: `Bearer ${this.token}`
        //     }
        //   }).pipe(
        //     retry(1)
        //   )
        //   const observer = {
        //     next: (response) => {
        //       const data = response.response
        //       this.trail = data
        //     },
        //     error: (err) => {
        //       const status = err.status
        //       if (status === 401) {
        //         this.$router.push('/')
        //       }
        //     },
        //     complete: () => {
        //       this.showTrail = true
        //     }
        //   }
        //   observable.subscribe(observer)
        // }
      }
    }
  }
  </script>
  
  <style>
  
  #chart-container {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 40vh;
  }
  
  </style>
  