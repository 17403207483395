<template>
    <div id="navbar-x" class="border-0 text-left shadow-lg flex justify-between bg-gradient-to-r from-emerald-600 via-emerald-500 to-emerald-700">
      <div class="flex items-center pl-4 md:px-6 py-2.5 space-x-1 transform">
        <div @click="home()" class="flex space-x-1 cursor-pointer items-center">
          <img src="../assets/round-logo.png" class="ml-4 h-6 w-6 font-bold text-white hidden  md:block cursor-pointer "/>
          <span class=" text-size-sm font-bold text-white">EGPAF Lesotho</span>
        </div>
        <div class="md:pl-32 h-full">
          <svg @click="sidebarDismiss()" class="h-5 w-5 cursor-pointer text-white fill-current ml-6" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z"/><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"/><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"/><circle cx="2" cy="5" r="2"/><circle cx="2" cy="12" r="2"/><circle cx="2" cy="19" r="2"/></svg>
        </div>
      </div>
      <div class="px-6 py-3 items-center flex space-x-5">
        <!-- <svg @click="logout()" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white fill-current cursor-pointer hover:scale-105 transform" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24" width="512" height="512"><path d="M16,2.764V6.082a8,8,0,1,1-8,0V2.764a11,11,0,1,0,8,0Z"/><rect x="10.5" width="3" height="8"/></svg> -->
        <svg @click="logout()" class="h-4 w-4 text-white fill-current cursor-pointer hover:scale-105 transform" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
          <path d="M12,8.28c.829,0,1.5-.671,1.5-1.5V2.5c0-.829-.671-1.5-1.5-1.5s-1.5,.671-1.5,1.5V6.78c0,.829,.671,1.5,1.5,1.5Z"/>
          <path d="M18.441,3.093c-.719-.414-1.636-.168-2.049,.55-.414,.717-.168,1.635,.549,2.049,1.151,.664,2.682,2.078,2.682,6.666,0,6.364-2.917,7.643-7.623,7.643s-7.624-1.278-7.624-7.643c0-4.571,1.532-5.989,2.685-6.658,.716-.416,.96-1.334,.544-2.05-.416-.716-1.333-.96-2.05-.544C2.744,4.737,1.376,7.764,1.376,12.357c0,9.54,6.063,10.643,10.624,10.643s10.623-1.103,10.623-10.643c0-4.61-1.368-7.641-4.182-9.265Z"/>
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { interval } from 'rxjs'

  export default {
    computed: {
      ...mapState([
        'id',
        'loginId',
        'token',
        'email',
        'sidebarClosed'
      ])
    },
    components: {
    },
    setup () {
      return {
        dissmiss: false
      }
    },
    name: 'nav-bar',
    props: {
    },
    data: () => {
      return {
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
      this.tokenCheck()
      // detects the device 
      // & automatically closes the sidebar
      // if its a small device
      this.deviceDetection()
    },
    methods: {
      // detect if the device is a mobile device
      // if yes close the sidebar
      deviceDetection () {
        if(this.$isMobile()) {
          this.dissmiss = this.sidebarClosed
          const sidebar = document.querySelector('.sidebar')
          sidebar.classList.toggle('-translate-x-full')
          this.dissmiss = true
          this.$store.commit('mutateSidebarClosed', this.dissmiss)
          this.$emit('sidebarDismissed', this.dissmiss)
        } 
      },
      // dismisses the sidebar
      sidebarDismiss () {
        if (this.$isMobile()) {
          this.dissmiss = this.sidebarClosed
        }
        
        const sidebar = document.querySelector('.sidebar')
        sidebar.classList.toggle('-translate-x-full')
        this.dissmiss = !this.dissmiss
        this.$store.commit('mutateSidebarClosed', this.dissmiss)
        this.$emit('sidebarDismissed', this.dissmiss)
      },
      // logs out users
      logout () {
        const observable = ajax({
          url: this.BASE_URL + '/login/signout',
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          body: {
            id: this.loginId
          }
        })

        const observer = {
          next: () => {
          },
          error: () => {
            this.$router.push('/')
          },
          complete: () => {
            this.$router.push('/')
          }
        }
  
        observable.subscribe(observer)
      },
      // check the validity of the user token
      tokenCheck () {
        const observable = ajax({
          url: this.BASE_URL + '/employee/findById',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          body: {
            id: this.loginId
          }
        })

        const observer = {
          next: () => {
          },
          error: () => {
            this.$router.push('/')
          },
          complete: () => {
            // this.tokenAutoCheck()
          }
        }
  
        observable.subscribe(observer)
      },
      tokenAutoCheck () {
        const observable = interval(1000 * 60)
        const observer = {
          next: () => {
            // this.tokenAutoCheck()
          },
          error: () => {}
        }

        observable.subscribe(observer)
      },
      // go to the home page
      home () {
        this.$router.push('/employee-home')
      }
    }
  }
  </script>
  
  <style>
  </style>
  