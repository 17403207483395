<template>
    <div class="SpecificObjectives">
      <SpecificObjectives/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import SpecificObjectives from '@/components/SpecificObjectives.vue'
  
  export default {
    name: 'admin-SpecificObjectives-view',
    components: {
      SpecificObjectives
    }
  }
  </script>
  