<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Timeline</span> > Edit Memo</span>
          <span>{{ firstname }} {{ surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m14.414 0h-9.414a3 3 0 0 0 -3 3v21h20v-16.414zm.586 3.414 3.586 3.586h-3.586zm-11 18.586v-19a1 1 0 0 1 1-1h8v7h7v13zm9-8h3v2h-3v3h-2v-3h-3v-2h3v-3h2z"/></svg> -->
              <svg class="h-4 w-4 fill-emerald-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m18,0H6c-1.654,0-3,1.346-3,3v21h18V3c0-1.654-1.346-3-3-3Zm1,22H5V3c0-.552.448-1,1-1h12c.552,0,1,.448,1,1v19ZM7,5h10v2H7v-2Zm0,5h10v2H7v-2Zm0,5h5v2h-5v-2Z"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Edit the Memo</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-5 mt-5">
              <span class="ml-4 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-500 ring-2 text-gray-500 py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-500"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-500 ring-2 text-gray-500 py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-500"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-500 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-400"></div>
                <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-500"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-4 ring-emerald-500 ring-2 text-gray-500 py-0 px-1">6</span>
            </div>
            <div v-if="dataLoaded" class="mx-8 mb-5 mt-5 border shadow px-4 py-8 grid grid-flow-row grid-cols-2 grid-rows-3 gap-x-4 gap-y-6 sm:gap-y-6  ">
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Request Type <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'requestTypeId'"
                      :url="'/request-type/search'"
                      :persistedSelection="selectedRequestType"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedRequestType = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Title <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <input
                    v-model="title"
                    placeholder="Enter activity title"
                    required
                    type="text"
                    class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Source of Funding <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'budgetSource'"
                      :url="'/budget-source/search'"
                      :persistedSelection="selectedBudgetSources"
                      :multipleSelection="true"
                      @selectedItems="(items) => {
                        selectedBudgetSources = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Activity Type <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'activityType'"
                      :url="'/activity-type/search'"
                      :persistedSelection="selectedActivityType"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedActivityType = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Stakeholders <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'activityStakeholder'"
                      :url="'/stakeholder/search'"
                      :persistedSelection="selectedActivityStakeholders"
                      :multipleSelection="true"
                      @selectedItems="(items) => {
                        selectedActivityStakeholders = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Activity Specific Objectives <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'specificObjective'"
                      :url="'/specific-objective/search'"
                      :persistedSelection="selectedSpecificObjective"
                      :multipleSelection="true"
                      @selectedItems="(items) => {
                        selectedSpecificObjective = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Destination <span class="font-bold text-red-500">*</span></label>
                <div class="flex w-full">
                  <div class="w-full">
                    <OfflineDropDown
                      :elementId="'destination'"
                      :persistedSelection="selectedDestination"
                      :buttonLabel="'Select the Destination'"
                      :multipleSelection="false"
                      :dataSource="destinations"
                      @selectedItems="(items) => {
                        selectedDestination = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Request Currency <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'currencyId'"
                      :url="'/currency/search'"
                      :persistedSelection="selectedCurrency"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedCurrency = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name', 'acronym']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Book Accommodation <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'accommodationId'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedAccommodation"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedAccommodation = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Book Transportation <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'transport'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedTransportation"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedTransportation = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Book Conferencing <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'conference'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedConference"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedConference = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Is The Activity Budgeted? <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'budgetedId'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedBudgeted"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedBudgeted = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Is The Activity In Your Workplan? <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <div class=" w-full">
                    <CustomDropDown
                      :elementId="'plannedId'"
                      :url="'/choice/search'"
                      :persistedSelection="selectedPlanned"
                      :multipleSelection="false"
                      @selectedItems="(items) => {
                        selectedPlanned = items
                      }"
                      :display="{
                        id: 'id',
                        name: ['name']
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Expected Number of Participants <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <input
                    v-model="participants"
                    placeholder="Enter expected no of participants"
                    requiemerald
                    class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                </div>
              </div>
              <div class="col-span-full sm:col-span-1 ">
                <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Additional Notes <span class="font-bold text-red-500">*</span></label>
                <div class="flex ">
                  <input
                    v-model="notes"
                    placeholder="Enter additional notes"
                    requiemerald
                    class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                </div>
              </div>
            </div>
            <div class="col-span-full sm:col-span-1">
              <div class="flex-1 mb-10 space-x-0.5">
                <button
                  v-if="selectedAccommodation.name === 'Yes' || selectedConference.name === 'Yes'"
                  @click="viewPR()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    PR Form
                </button>
                <button
                  @click="viewPercentages()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Budget Percentages
                </button>
                <button
                  @click="next()"
                  class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                    Next
                </button>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <MessageModal
      :isOk="isOk"
      :title="messageTitle"
      :message="message"
      ref="messageModal"
    />
    <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message="saveMessage" />
       <!-- the budget percentage modal -->
     <BudgetPercentageModal
        v-if="showPercentageModal"
        :isOk="isOk"
        :title="messageTitle"
        :message="message"
        :budgetSources="selectedBudgetSources"
        ref="budgetPercentageModal"
        @error="(error) => {
          percentageError(error)
        }"
        @success="(percentageData) => {
          savePercentageData(percentageData)
        }"
      />
      <!-- the pr modal -->
      <PRModal ref="prModal" :conferencing="selectedConference" :accommodation="selectedAccommodation"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/SideBar.vue'
import MessageModal from './MessageModal.vue'
import { mapState } from 'vuex'
import OfflineDropDown from './OfflineDropDown.vue'
import CustomDropDown from './CustomDropDown.vue'
import { interval } from 'rxjs'
import PopUp from './PopUp.vue'
import BudgetPercentageModal from './BudgetPercentageModal.vue'
import PRModal from './EditPRModal.vue'

export default {
  computed: {
    ...mapState([
      'editMemo'
    ])
  },
  components: {
    PopUp,
    Navbar,
    Sidebar,
    MessageModal,
    CustomDropDown,
    OfflineDropDown,
    BudgetPercentageModal,
    PRModal
  },
  setup () {
    return {
    }
  },
  name: 'edit-memo',
  props: {
  },
  data: () => {
    return {
      isOk: true,
      message: '',
      messageTitle: '',
      startDate: '',
      endDate: '',
      title: '',
      objective: '',
      noOfParticipants: 1,
      notes: '',
      keyContacts: '',
      expandBody: false,
      dataLoaded: false,
      selectedBudgetSources: {},
      selectedActivityType: {},
      selectedActivityStakeholders: {},
      selectedSpecificObjective: {},
      selectedCurrency: {},
      selectedDestination: {},
      selectedAccommodation: {},
      selectedTransportation: {},
      selectedConference: {},
      selectedOffice: {},
      selectedRequestType: {},
      selectedBudgeted: {},
      selectedPlanned: {},
      saveMessage: process.env.VUE_APP_SAVE_MESSAGE,
      destinations: [
        { id: 1, name: 'Maseru' },
        { id: 2, name: 'Local Outside Maseru' },
        { id: 3, name: 'Abroad / Outside Lesotho' }
      ],
      showPercentageModal: false
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 3)
    this.$store.commit('mutateSelectedSubMenuItem', null)
    // // load the vuex data
    this.loadMemoData()
    // start automatically saving the data
    this.autosave()
  },
  methods: {
    // save the percentage details
    savePercentageData (data) {
      this.showPercentageModal = false
      this.selectedBudgetSources = data
    },
    viewPR () {
      this.$refs.prModal.openPRModal()  
    },
    viewPercentages () {
      this.showPercentageModal = true
      setTimeout(() => {
        this.$refs.budgetPercentageModal.openMessageModal()      
      }, 800)
    },
    autosave() {
      this.$refs.popupRef.show()
      const observable = interval(1000) // Emit every second
      const observer = {
        next: () => {
          const dataToSave = {
            title: this.title,
            budgetSource: this.selectedBudgetSources,
            requestType: this.selectedRequestType,
            activityType: this.selectedActivityType,
            stakeholders: this.selectedActivityStakeholders,
            specificObjectives: this.selectedSpecificObjective,
            currency: this.selectedCurrency,
            accommodation: this.selectedAccommodation,
            transportation: this.selectedTransportation,
            conferencing: this.selectedConference,
            budgeted: this.selectedBudgeted,
            planned: this.selectedPlanned,
            expectedParticipants: this.participants,
            notes: this.notes,
            destination: this.selectedDestination
          }
          this.$store.commit('mutateEditMemo', dataToSave)
        },
        error: () => {}
      }
      observable.subscribe(observer)
    },
    openMessageModal () {
      this.isOk = false
      this.messageTitle = 'Error Message'
      this.message = 'Fill all the fields in the memo to move to the next step...'
      this.$refs.messageModal.openMessageModal()
    },
    // load the stoemerald memo data
    loadMemoData () {
      if (this.editMemo !== null) {
        this.title = this.editMemo.title
        this.participants = this.editMemo.expectedParticipants
        this.notes = this.editMemo.notes
        this.selectedBudgetSources = this.editMemo.budgetSource
        this.selectedRequestType = this.editMemo.requestType
        this.selectedActivityType = this.editMemo.activityType
        this.selectedActivityStakeholders = this.editMemo.stakeholders
        this.selectedSpecificObjective = this.editMemo.specificObjectives
        this.selectedCurrency = this.editMemo.currency
        this.selectedAccommodation = this.editMemo.accommodation
        this.selectedTransportation = this.editMemo.transportation
        this.selectedConference = this.editMemo.conferencing
        this.selectedPlanned = this.editMemo.planned
        this.selectedBudgeted = this.editMemo.budgeted
        
        if (this.editMemo.destination.id !== undefined && this.editMemo.destination.id !== null) {
          this.selectedDestination = this.editMemo.destination
        }
        
        this.dataLoaded = true

        console.log(this.editMemo)
      }
    },
    next () {
      // moving to the next step
      if (
        this.title.trim() !== '' &&
        !isNaN(this.participants) &&
        this.notes.trim() !== '' &&
        Array.isArray(this.selectedBudgetSources) &&
        this.selectedRequestType.id !== undefined &&
        this.selectedActivityType.id !== undefined &&
        Array.isArray(this.selectedActivityStakeholders) &&
        Array.isArray(this.selectedSpecificObjective) && 
        this.selectedCurrency.id !== undefined &&
        this.selectedAccommodation.id !== undefined &&
        this.selectedTransportation.id !== undefined &&
        this.selectedConference.id !== undefined &&
        this.selectedPlanned.id !== undefined &&
        this.selectedBudgeted.id !== undefined
      ) {
        this.$router.push('edit-budget')
      } else {
        // showing the dialog for the error
        this.isOk = true
        this.messageTitle = 'Message'
        this.message = 'Please fill all the fields to move to the next step...'
        this.$refs.messageModal.openMessageModal()
      }
      
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
