<template>
    <div class="timeline">
      <ProxyManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ProxyManagement from '@/components/ProxyManagement.vue'
  
  export default {
    name: 'proxy-management-view',
    components: {
      ProxyManagement
    }
  }
  </script>
  