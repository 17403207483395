<template>
    <div>
      <LiquidationApprovalHistory/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import LiquidationApprovalHistory from '@/components/LiquidationApprovalHistory.vue'
  
  export default {
    name: 'liquidation-approval-history-view',
    components: {
      LiquidationApprovalHistory
    }
  }
  </script>
  