<template>
    <div>
      <CreateMemo/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import CreateMemo from '@/components/CreateMemo.vue'
  
  export default {
    name: 'create-memo-view',
    components: {
      CreateMemo
    }
  }
  </script>
  