<template>
    <div>
      <EditRequestSummary/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import EditRequestSummary from '@/components/EditRequestSummary.vue'
  
  export default {
    name: 'edit-request-summary',
    components: {
      EditRequestSummary
    }
  }
  </script>
  