<template>
  <div>
    <DownloadRequests/>
  </div>
</template>

<script>
// @ is an alias to /src
import DownloadRequests from '@/components/DownloadRequests.vue'

export default {
  name: 'approve-request-view',
  components: {
    DownloadRequests
  }
}
</script>
