<template>
    <div>
      <CreateSummary/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import CreateSummary from '@/components/CreateSummary.vue'
  
  export default {
    name: 'create-summary-view',
    components: {
      CreateSummary
    }
  }
  </script>
  