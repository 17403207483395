<template>
    <div>
      <CreateBudget/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import CreateBudget from '@/components/CreateBudget.vue'
  
  export default {
    name: 'create-budget-view',
    components: {
      CreateBudget
    }
  }
  </script>
  