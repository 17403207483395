<template>
    <div>
      <CreateAttachment/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import CreateAttachment from '@/components/CreateAttachment.vue'
  
  export default {
    name: 'create-attachment-view',
    components: {
      CreateAttachment
    }
  }
  </script>
  