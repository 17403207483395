<template>
    <div class="RequestTypeManagement">
      <RequestTypeManagement/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import RequestTypeManagement from '@/components/RequestTypeManagement.vue'
  
  export default {
    name: 'request-type-management-view',
    components: {
      RequestTypeManagement
    }
  }
  </script>
  