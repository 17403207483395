<template>
    <div class="DeactivatedAccounts">
      <DeactivatedAccounts/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import DeactivatedAccounts from '@/components/DeactivatedAccounts.vue'
  
  export default {
    name: 'admin-DeactivatedAccounts-view',
    components: {
      DeactivatedAccounts
    }
  }
  </script>
  