<template>
    <div class="MainObjectives">
      <MainObjectives/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import MainObjectives from '@/components/MainObjectives.vue'
  
  export default {
    name: 'admin-MainObjectives-view',
    components: {
      MainObjectives
    }
  }
  </script>
  