<template>
    <div>
        <!-- The loader dialog box -->
        <TransitionRoot appear :show="isLoaderOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full bg-white max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-2xl rounded-sm"
                >
                  <div class="mt-2 justify-center">
                    <div class="flex flex-col items-center">
                        <div class="mt-2">
                        <button
                          type="button"
                          class="text-size text-gray-500 focus:outline-none focus-visible:ring-2
                                focus-visible:ring-offset-2 focus-visible:ring-white"
                        >
                          {{ message }}
                        </button>
                        <!--- The login loader -->
                        <span class="loader"></span>
                      </div>
                    </div>
                    <div class="mt-4 flex justify-center">
                  </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
        </TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  
  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
      // DialogTitle
    },
    setup () {
      const isLoaderOpen = ref(false)
      return {
        isLoaderOpen,
        closeLoaderModal () {
          isLoaderOpen.value = false
        },
        openLoaderModal () {
          isLoaderOpen.value = true
        }
      }
    },
    name: 'loading-modal',
    props: {
      message: String
    },
    data: () => {
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  
  .loader {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #FFF;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 2s linear infinite;
  }
  
  @keyframes shadowRolling {
    0% {
      box-shadow: 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    12% {
      box-shadow: 100px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    25% {
      box-shadow: 110px 0 rgba(5, 160, 105, 0.8), 100px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    36% {
      box-shadow: 120px 0 rgba(5, 160, 105, 0.8), 110px 0 rgba(5, 160, 105, 0.8), 100px 0 rgba(5, 160, 105, 0.8), 0px 0 rgba(5, 160, 105, 0.8);
    }
    50% {
      box-shadow: 130px 0 rgba(5, 160, 105, 0.8), 120px 0 rgba(5, 160, 105, 0.8), 110px 0 rgba(5, 160, 105, 0.8), 100px 0 rgba(5, 160, 105, 0.8);
    }
    62% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 130px 0 rgba(5, 160, 105, 0.8), 120px 0 rgba(5, 160, 105, 0.8), 110px 0 rgba(5, 160, 105, 0.8);
    }
    75% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 130px 0 rgba(5, 160, 105, 0.8), 120px 0 rgba(5, 160, 105, 0.8);
    }
    87% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 130px 0 rgba(5, 160, 105, 0.8);
    }
    100% {
      box-shadow: 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8), 200px 0 rgba(5, 160, 105, 0.8);
    }
  }
  #cloud{
      display: none;
      /* position: absolute; */
      /* animation: move-plane infinite forwards; */
  }
  #cloud_2{
      /* position: absolute; */
      /* animation: move-plane infinite forwards; */
  }
  
  #plane{
      /* position: absolute; */
    animation: move-plane 72s infinite forwards ease-in-out;
  }
  
  @keyframes move-plane {
    0%{ transform: translate(0px, 0px);}
    25%{ transform: translate(200px, 0px);}
    50%{ transform: translate(400px, 0px);}
    75%{ transform: translate(600px, 0px);}
    100%{ transform: translate(640px, 0px);}
    /* 50%{ transform: translate(1052px, 150px);} */
    /* 100%{ transform: translate(1054px, 150px);} */
  }
  </style>
  