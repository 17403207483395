<template>
  <div>
    <ApprovedRequest/>
  </div>
</template>
  
  <script>
  // @ is an alias to /src
  import ApprovedRequest from '@/components/ApprovedRequest.vue';
  
  export default {
    name: 'penidng-approval-view',
    components: {
      ApprovedRequest
    }
  }
  </script>
  