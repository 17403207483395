<template>
    <div>
      <CreateParticipants/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import CreateParticipants from '@/components/ParticipantPayments.vue'
  
  export default {
    name: 'participant-payments-view',
    components: {
      CreateParticipants
    }
  }
  </script>
  